import { Button, Table, Input, Popconfirm, message, Typography } from 'antd'
import React, { FC, useState, ReactText, useEffect } from 'react'
import { actions as employeesActions } from 'store/modules/employees/slice'
import { useSelector, useDispatch } from 'react-redux'
import { selectDeletedEmployees } from 'store/modules/employees/selectors'
import {
    filterEmployeesByNameOrEmail,
    getEmployeeName,
    getUserDisplayRoleByUserRole,
} from 'utils/employees'
import type { ColumnProps } from 'antd/lib/table'
import { Employee, DisplayRole } from 'types/employee'
import type { SortOrder } from 'antd/lib/table/interface'
import ErrorState from 'components/error-state/error-state'
import { selectEmployeesStatus } from 'store/modules/support/selectors'
import dayjs from 'packages/dayjs'
import { useGetDeletedUser } from 'services/auth/users'
import { DeletedUser } from './deleted-user/deleted-user'

import styled from 'styled-components'
import { inputWidth, spacing } from 'theme/tokens'
import { withPermissionGuard } from 'components/permission-guard/permission-guard'
import { BreakText } from 'components/layout-containers'

const { Text } = Typography

interface Props {
    companyId: string
}

export const ReactivateEmployeeForm: FC<React.PropsWithChildren<Props>> = ({ companyId }) => {
    const dispatch = useDispatch()
    const fetchEmployees = () =>
        dispatch(
            employeesActions.fetchCompanyEmployees({ deimosId: companyId, includeDeleted: true })
        )

    const employees = useSelector(selectDeletedEmployees)
    const employeesStatus = useSelector(selectEmployeesStatus)

    const [filter, setFilter] = useState('')
    const [disableButton, setDisableButton] = useState(true)
    const [selectedEmployeeId, setSelectedEmployeeId] = useState('')
    const { data: selectedUser, error, isValidating } = useGetDeletedUser(selectedEmployeeId)
    const handleFilter = (event: any) => setFilter(event.target.value)
    const filteredEmployees = filterEmployeesByNameOrEmail(employees, filter)

    useEffect(() => {
        if (error) {
            message.error(error.message)
        }
    }, [error])

    useEffect(() => {
        setDisableButton(!selectedUser)
    }, [selectedUser])

    const reactivateUser = () => {
        if (selectedUser && selectedEmployeeId !== undefined) {
            dispatch(
                employeesActions.reactivateEmployee({
                    employeeId: selectedEmployeeId,
                    userId: selectedUser.id,
                })
            )
            setSelectedEmployeeId('')
            setDisableButton(true)
            message.success('User reactivated')
        }
    }

    const columns: ColumnProps<Employee>[] = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            sorter: (a: Employee, b: Employee) =>
                getEmployeeName(a).localeCompare(getEmployeeName(b)),
            defaultSortOrder: 'ascend' as SortOrder,
        },
        {
            title: 'Employee ID',
            dataIndex: 'id',
            render: (id: string) => <Text copyable>{id}</Text>,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            filters: Object.values(DisplayRole).map((value) => ({
                value,
                text: value,
            })),
            onFilter: (value, employee) => getUserDisplayRoleByUserRole(employee.role) === value,
            render: (role: Employee['role']) => <span>{getUserDisplayRoleByUserRole(role)}</span>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (email) => <BreakText>{email}</BreakText>,
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (createdDate: string) => dayjs(createdDate).format('lll'),
        },
        {
            title: 'Deactivated at',
            dataIndex: 'deletedAt',
            render: (deletedDate: string) => dayjs(deletedDate).format('lll'),
        },
    ]
    switch (employeesStatus) {
        case 'error':
            return <ErrorState onRetry={fetchEmployees} />
        case 'fetching':
        case 'default':
        case 'resolved':
            return (
                <Content>
                    <StyledInput
                        name="employeeFilter"
                        placeholder="Filter by employee name"
                        onChange={handleFilter}
                    />
                    <Table
                        rowSelection={{
                            type: 'radio',
                            onChange: (selectedRows: ReactText[]) => {
                                if (selectedRows.length) {
                                    setSelectedEmployeeId(selectedRows[0].toString())
                                    setDisableButton(true)
                                }
                            },
                        }}
                        dataSource={filteredEmployees}
                        columns={columns}
                        loading={isValidating}
                        rowKey="id"
                        size="small"
                    />
                    {selectedUser && <DeletedUser user={selectedUser} />}
                    <ButtonContainer>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={reactivateUser}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="primary"
                                data-testid="reactivateButton"
                                disabled={disableButton}
                            >
                                Reactivate
                            </Button>
                        </Popconfirm>
                    </ButtonContainer>
                </Content>
            )
    }
}

const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${spacing.space24};
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
`

const StyledInput = styled(Input)`
    width: ${inputWidth.large};
`

export default withPermissionGuard(ReactivateEmployeeForm, ['company-role'])
