import React, { FC, useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import { inputWidth } from 'theme/tokens'
import { ActionsContainer, BreakText, ContentContainer } from 'components/layout-containers'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Card, Input, Modal, Select, Space, Table, message, Typography } from 'antd'
import type { SortOrder } from 'antd/lib/table/interface'
import type { ColumnProps } from 'antd/es/table'
import dayjs from 'packages/dayjs'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import InviteEmployeeForm from './invite-employee-form/invite-employee-form'
import { Employee, EmployeePlusInsurance, EmployeePayload, DisplayRole, Role } from 'types/employee'
import type { BFFBlockListItem } from 'components/employees/employees.bff'
import { selectEmployees, selectStatus } from 'store/modules/employees/selectors'
import { actions as userActions } from 'store/modules/support/user/slice'
import { actions } from 'store/modules/employees/slice'
import {
    filterEmployeesByNameOrEmail,
    getEmployeeName,
    getUserDisplayRoleByUserRole,
} from 'utils/employees'
import { ReactivateEmployeeForm } from 'components/reactivate-employee/reactivate-employee-form'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { useNavigate } from 'react-router-dom'
import { bff } from 'components/bff-hooks'
import type { CreateInsuranceRequestCountryCode } from 'bff/moons/generated/epimetheus'
import { downloadFetchConnectedEmployeesAsCSV } from 'services/callisto/fetch-connected-employees'
const { confirm } = Modal
const { Text } = Typography
import type { CompanyResponseModel } from 'bff/moons/generated/oberon-v1'

interface Props {
    companyId: string
    fetchingEmployees: boolean
    onConfirmDeactivate: (employeesToDeactivate: EmployeePayload[]) => void
    onChangeRole: (employeeId: string, userId: string) => (role: Role) => void
    canChangeRole: boolean
    onEmployeeClick: (employee: Employee) => void
    onConfirmBlockPhoneNumber: (phoneNumber: string) => void
    onConfirmUnblockPhoneNumber: (phoneNumber: string) => void
    blockedEmployees: BFFBlockListItem[] | undefined
    company?: CompanyResponseModel
    onEnrollInInsurance: (employeeIds: string[]) => void
    employeesPlusInsurance: EmployeePlusInsurance[]
    insuredEmployeeIds: string[]
    isLoadingEnrollInsurance: boolean
}

const { Option } = Select

export const Employees: FC<React.PropsWithChildren<Props>> = ({
    companyId,
    fetchingEmployees,
    onConfirmDeactivate,
    onChangeRole,
    canChangeRole,
    onEmployeeClick,
    onConfirmBlockPhoneNumber,
    onConfirmUnblockPhoneNumber,
    blockedEmployees,
    onEnrollInInsurance,
    employeesPlusInsurance,
    insuredEmployeeIds,
    isLoadingEnrollInsurance,
}) => {
    const [selectedEmployees, setSelectedEmployees] = useState<EmployeePayload[]>([])
    const [reactivateEmployeeVisible, setReactivateEmployeeVisible] = useState(false)

    const insuredEmployeeSelected = selectedEmployees.find((employee) =>
        insuredEmployeeIds.includes(employee.employeeId)
    )
    const alreadyInsuredSelected = Boolean(insuredEmployeeSelected)

    const isInsuranceEndDateInTheFuture = (insuranceEndDate: string) => {
        return Boolean(new Date() < new Date(insuranceEndDate))
    }

    const columns: ColumnProps<Employee>[] = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            render: (_: string, employee: Employee) => (
                <Button
                    onClick={() => onEmployeeClick(employee)}
                    type="link"
                    style={{ padding: 0 }}
                >
                    {getEmployeeName(employee)}
                </Button>
            ),
            sorter: (a: Employee, b: Employee) =>
                getEmployeeName(a).localeCompare(getEmployeeName(b)),
            defaultSortOrder: 'ascend' as SortOrder,
        },
        {
            title: 'Insurance status',
            dataIndex: 'insuranceEndDate',
            render: (insuranceEndDate) => {
                if (!insuranceEndDate) {
                    return <div data-testid="insuranceEndDate">Not insured</div>
                } else if (isInsuranceEndDateInTheFuture(insuranceEndDate)) {
                    return (
                        <div data-testid="insuranceEndDate">{`Active (until ${dayjs(
                            insuranceEndDate
                        ).format('ll')})`}</div>
                    )
                } else if (!isInsuranceEndDateInTheFuture(insuranceEndDate)) {
                    return (
                        <div data-testid="insuranceEndDate">{`Expired (expired on ${dayjs(
                            insuranceEndDate
                        ).format('ll')})`}</div>
                    )
                }
            },
        },
        {
            title: 'Plan code',
            dataIndex: 'planCode',
            render: (planCode) =>
                planCode ? (
                    <Text data-testid="planCode" copyable>
                        {planCode}
                    </Text>
                ) : null,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            filters: Object.values(DisplayRole).map((value) => ({
                value,
                text: value,
            })),
            onFilter: (value, employee) => getUserDisplayRoleByUserRole(employee.role) === value,
            render: (role: Role, employee) =>
                canChangeRole ? (
                    <Select
                        value={role}
                        onChange={onChangeRole(employee.id, employee.userId)}
                        style={{ width: `${inputWidth.small}` }}
                    >
                        <Option value="owner">{getUserDisplayRoleByUserRole(Role.OWNER)}</Option>
                        <Option value="member">{getUserDisplayRoleByUserRole(Role.MEMBER)}</Option>
                    </Select>
                ) : (
                    getUserDisplayRoleByUserRole(role)
                ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (email) => <BreakText>{email}</BreakText>,
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (createdAt) => dayjs(createdAt).format('lll'),
            sorter: (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
        },
        {
            title: 'Action',
            dataIndex: 'id',
            fixed: 'right',
            render: (employeeId: string, { phone, email }: Employee) => (
                <ActionsContainer>
                    {blockedEmployees?.some((employee) => employee.phonePattern === phone) ? (
                        <Button
                            type="link"
                            danger
                            onClick={() => onConfirmUnblockPhoneNumber(phone)}
                        >
                            Unblock
                        </Button>
                    ) : (
                        <Button type="link" danger onClick={() => onConfirmBlockPhoneNumber(phone)}>
                            Block
                        </Button>
                    )}
                    <Button
                        type="link"
                        danger
                        onClick={() => onConfirmDeactivate([{ employeeId, email }])}
                    >
                        Deactivate
                    </Button>
                </ActionsContainer>
            ),
        },
    ]
    return (
        <ContentContainer>
            <Table
                loading={fetchingEmployees || isLoadingEnrollInsurance}
                dataSource={employeesPlusInsurance}
                columns={columns}
                pagination={{ pageSizeOptions: ['10', '20', '50', '100', '200'] }}
                scroll={{ x: 'max-content' }}
                rowKey="id"
                rowSelection={{
                    type: 'checkbox',
                    onChange: (_, selectedRows) => {
                        const employeeMap = selectedRows.map(({ email, id }) => ({
                            employeeId: id,
                            email,
                        }))
                        setSelectedEmployees(employeeMap)
                    },
                }}
            />
            <Space>
                <Button
                    danger
                    disabled={!selectedEmployees.length}
                    onClick={() => onConfirmDeactivate(selectedEmployees)}
                >
                    Deactivate employees
                </Button>
                <Button onClick={() => setReactivateEmployeeVisible(true)}>
                    Reactivate employee
                </Button>
                <Button
                    type="primary"
                    disabled={!selectedEmployees.length || alreadyInsuredSelected}
                    onClick={async () => {
                        const employeeIds = selectedEmployees.map((employee) => employee.employeeId)
                        await onEnrollInInsurance(employeeIds)
                    }}
                    loading={isLoadingEnrollInsurance}
                >
                    Enroll employees in insurance
                </Button>
            </Space>
            <Modal
                title="Reactivate employee"
                open={reactivateEmployeeVisible}
                onCancel={() => setReactivateEmployeeVisible(false)}
                footer={null}
                width={1000}
                centered
                destroyOnClose
            >
                <ReactivateEmployeeForm companyId={companyId} />
            </Modal>
        </ContentContainer>
    )
}

const EmployeesContainer: FC<React.PropsWithChildren<{ companyId: string }>> = ({ companyId }) => {
    const [filter, setFilter] = useState('')
    const [isInviteEmployeeModalVisible, setIsInviteEmployeeModalVisible] = useState(false)
    const dispatch = useDispatch()
    const status = useSelector(selectStatus)
    const employees = useSelector(selectEmployees)
    const canChangeEmployeeRole = useHasPermissions(['company-role', 'styx']) // only 'company-role' enforced in backend
    const navigate = useNavigate()
    const handleFilter = (event: ChangeEvent<HTMLInputElement>) => setFilter(event.target.value)
    const onEmployeeClick = (employee: Employee) =>
        navigate(`/customer-success/users/${employee.userId}`)
    const filteredEmployees = filterEmployeesByNameOrEmail(employees, filter)
    const { data: blockedEmployees } = bff.components.employees.getBlockedEmployees.useQuery()
    const { mutate: blockPhoneNumber } = bff.components.employees.blockPhoneNumber.useMutation()
    const { mutate: unblockPhoneNumber } = bff.components.employees.unblockPhoneNumber.useMutation()

    const { data: insuredEmployees } = bff.components.employees.getInsuredEmployees.useQuery({
        companyId,
    })

    const { data: company } = bff.components.employees.getCompanyById.useQuery({ companyId })

    const {
        mutate: enrollEmployees,
        error: enrollInsuranceError,
        isLoading: isLoadingEnrollInsurance,
        isSuccess: enrollInsuranceSuccess,
    } = bff.components.employees.enrollEmployeesInInsurance.useMutation()

    const insuredEmployeeIds = (insuredEmployees ?? []).map(
        (insuredEmployee: any) => insuredEmployee.employeeId
    )

    const employeesPlusInsurance = (filteredEmployees ?? []).map((employee) => {
        const matchedEmployee =
            employee?.id && insuredEmployees && insuredEmployees.length
                ? insuredEmployees.find(
                      (insuredEmployee: any) => insuredEmployee.employeeId === employee.id
                  )
                : null
        return {
            ...employee,
            isInsured: insuredEmployeeIds.includes(employee?.id),
            planCode: matchedEmployee?.planCode,
            insuranceEndDate: matchedEmployee?.insuranceEndDate,
        }
    })

    React.useEffect(() => {
        dispatch(actions.fetchCompanyEmployees({ deimosId: companyId, includeDeleted: true }))
    }, [companyId, dispatch])

    React.useEffect(() => {
        if (enrollInsuranceError) {
            message.error(enrollInsuranceError.message)
        }
    }, [enrollInsuranceError])

    React.useEffect(() => {
        if (enrollInsuranceSuccess) {
            message.success('Successfully enrolled employees in insurance')
        }
    }, [enrollInsuranceSuccess])

    async function onEnrollInInsurance(employeeIds: string[]) {
        await enrollEmployees({
            employeeIds,
            countryCode: company?.address?.country as CreateInsuranceRequestCountryCode,
            companyId,
        })
    }

    function onConfirmBlockPhoneNumber(phoneNumber: string) {
        confirm({
            centered: true,
            icon: <ExclamationCircleOutlined />,
            title: 'Block this employee?',
            content:
                'This will add the employee to the User Blocklist and prevent them from signing up with the same phone number.',

            async onOk() {
                try {
                    await blockPhoneNumber({ phoneNumber })
                    message.success(`Phone number ${phoneNumber} successfully blocked`)
                } catch (error) {
                    message.error(`Failed to block phone number ${phoneNumber}: ${error}`)
                }
            },
        })
    }

    function onConfirmUnblockPhoneNumber(phoneNumber: string) {
        confirm({
            centered: true,
            icon: <ExclamationCircleOutlined />,
            title: 'Unblock this employee?',
            content:
                'This will unblock the employee from the User Blocklist and allow them to sign up with this phone number.',
            async onOk() {
                try {
                    await unblockPhoneNumber({ phoneNumber })
                    message.success(`Phone number ${phoneNumber} successfully unblocked`)
                } catch (error) {
                    message.error(`Failed to block phone number ${phoneNumber}: ${error}`)
                }
            },
        })
    }

    return (
        <Card>
            <ContentContainer>
                <Row>
                    <Input
                        placeholder="Filter by employee name"
                        onChange={handleFilter}
                        style={{ width: `${inputWidth.medium}` }}
                    />
                    <Row>
                        <Button
                            onClick={() => downloadFetchConnectedEmployeesAsCSV(companyId)}
                            style={{ marginRight: 12 }}
                        >
                            Download Fetch users
                        </Button>
                        <Button onClick={() => setIsInviteEmployeeModalVisible(true)}>
                            Invite employee
                        </Button>
                    </Row>
                </Row>
                <Employees
                    onEmployeeClick={onEmployeeClick}
                    canChangeRole={canChangeEmployeeRole}
                    companyId={companyId}
                    onConfirmDeactivate={(selectedEmployees: EmployeePayload[]) =>
                        confirm({
                            centered: true,
                            icon: <ExclamationCircleOutlined />,
                            title: 'Are you sure?',
                            content:
                                'This action will also destroy the employees cards and is NOT reversible.',
                            onOk() {
                                dispatch(actions.deactivateEmployees(selectedEmployees))
                            },
                        })
                    }
                    onChangeRole={(employeeId: string, userId: string) => (role: Role) =>
                        confirm({
                            centered: true,
                            icon: <ExclamationCircleOutlined />,
                            title: 'Are you sure?',
                            content: `This user will now be an ${getUserDisplayRoleByUserRole(
                                role
                            )}`,
                            onOk() {
                                dispatch(userActions.changeRole({ employeeId, role, userId }))
                            },
                        })
                    }
                    onConfirmBlockPhoneNumber={onConfirmBlockPhoneNumber}
                    onConfirmUnblockPhoneNumber={onConfirmUnblockPhoneNumber}
                    fetchingEmployees={status === 'fetching'}
                    blockedEmployees={blockedEmployees}
                    onEnrollInInsurance={onEnrollInInsurance}
                    company={company}
                    employeesPlusInsurance={employeesPlusInsurance}
                    insuredEmployeeIds={insuredEmployeeIds}
                    isLoadingEnrollInsurance={isLoadingEnrollInsurance}
                />
                <Modal
                    destroyOnClose
                    title="Invite an employee"
                    open={isInviteEmployeeModalVisible}
                    onCancel={() => setIsInviteEmployeeModalVisible(false)}
                    footer={null}
                    width={400}
                >
                    <InviteEmployeeForm
                        onSubmit={({ email, firstName }) => {
                            dispatch(
                                actions.inviteEmployee({
                                    email,
                                    firstName: firstName || undefined,
                                    companyId,
                                })
                            )
                            setIsInviteEmployeeModalVisible(false)
                        }}
                    />
                </Modal>
            </ContentContainer>
        </Card>
    )
}

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`

export default EmployeesContainer
