import React, { FC, useState } from 'react'
import { Formik } from 'formik'
import { Form, FormItem, Input } from 'formik-antd'
import { Button, Popconfirm, Space, Typography } from 'antd'

import { FormValues, validationSchema } from './validation-schema'
import styled from 'styled-components'
import { inputWidth } from 'theme/tokens'
import type { User } from 'types/employee'
import { getUserTypes } from 'utils/user'

interface Props {
    initialValues: FormValues
    user: User
    canAdministrateBackoffice: boolean
    cardAccess?: boolean
    isOrganizationUser?: boolean
    onSubmit: (values: FormValues) => void
    onDeactivateEmployee: () => Promise<void>
    onDeactivatePartnerEmployee: () => Promise<void>
    onDeactivateUser: () => Promise<void>
    onGrantBackofficeAccess: () => void
    onRemoveBackofficeAccess: () => void
    onEnableCardAccess: () => void
}

const { Paragraph, Text } = Typography

const labelCol = {
    sm: { span: 6 },
}
const wrapperCol = { offset: 6, span: 12 }

const DeleteEmployeeConfirmation = () => (
    <Space direction="vertical">
        <Text strong>Deactivate employee?</Text>
        <Paragraph>
            By deleting the employee, <strong>employee personal details</strong> and{' '}
            <strong>contact information</strong> are lost and have to be retrieved by a technical
            engineer. This action will also destroy the <strong>employees cards</strong> and is NOT
            reversible.
            <br />
            <br />
            If this is the only role the users has the user will also be deleted!
        </Paragraph>
    </Space>
)

const DeletePartnerEmployeeConfirmation = () => (
    <Space direction="vertical">
        <Text strong>Delete partner employee?</Text>
        <Paragraph>
            By deleting the partner employee, <strong>partner employee personal details</strong> and{' '}
            <strong>contact information</strong> are lost and have to be retrieved by a technical
            engineer.
            <br />
            <br />
            If this is the only role the users has the user will also be deleted!
        </Paragraph>
    </Space>
)

const DeleteUserConfirmation = () => (
    <Space direction="vertical">
        <Text strong>Delete user?</Text>
        <Paragraph>
            By deleting the user, <strong>personal details</strong> and{' '}
            <strong>contact information</strong> are lost and have to be retrieved by a technical
            engineer.
        </Paragraph>
    </Space>
)

export const EditInformation: FC<React.PropsWithChildren<Props>> = ({
    initialValues,
    user,
    onSubmit,
    canAdministrateBackoffice,
    cardAccess,
    isOrganizationUser,
    onDeactivateEmployee,
    onDeactivatePartnerEmployee,
    onDeactivateUser,
    onGrantBackofficeAccess,
    onRemoveBackofficeAccess,
    onEnableCardAccess,
}) => {
    const userTypes = getUserTypes(user)
    const isPleoEmployee = initialValues.email.includes('@pleo.')
    const isPleoExt = initialValues.email.includes('@ext.pleo.')

    const [deleting, setDeleting] = useState(false)

    const wrapAction = (method: () => Promise<void>) => async (): Promise<void> => {
        if (deleting) {
            return
        }
        setDeleting(true)
        await method()
        setDeleting(false)
    }

    return (
        <Formik
            validateOnMount
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >
            {({ isValid }) => {
                return (
                    <Form labelCol={labelCol}>
                        <FormItem name="email" label="Email" colon>
                            <Input name="email" style={{ width: `${inputWidth.large}` }} />
                        </FormItem>
                        {userTypes.isBackofficeUser && canAdministrateBackoffice && (
                            <FormItem name="removeBackofficeAccess" wrapperCol={wrapperCol}>
                                <Popconfirm
                                    title={`Are you sure you want to REMOVE backoffice access for ${initialValues.email}?`}
                                    onConfirm={onRemoveBackofficeAccess}
                                    okText="Yes"
                                    cancelText="No"
                                    overlayStyle={{ width: '400px' }}
                                >
                                    <Button data-testid="remove-backoffice-access-button">
                                        Remove backoffice access
                                    </Button>
                                </Popconfirm>
                            </FormItem>
                        )}
                        {!userTypes.isBackofficeUser &&
                            (isPleoEmployee || isPleoExt) &&
                            canAdministrateBackoffice && (
                                <FormItem name="grantBackofficeAccess" wrapperCol={wrapperCol}>
                                    <Button
                                        data-testid="grant-backoffice-access-button"
                                        onClick={onGrantBackofficeAccess}
                                    >
                                        Grant backoffice access
                                    </Button>
                                </FormItem>
                            )}
                        {(userTypes.isCompanyMember || userTypes.isAdmin) && !cardAccess && (
                            <FormItem name="enableCardAccess" wrapperCol={wrapperCol}>
                                <Button
                                    data-testid="enable-card-access"
                                    onClick={onEnableCardAccess}
                                >
                                    Enable Card Access
                                </Button>
                            </FormItem>
                        )}
                        {(userTypes.isAdmin || userTypes.isEmployee) && !isOrganizationUser && (
                            <FormItem name="deactivate" wrapperCol={wrapperCol}>
                                <Popconfirm
                                    title={<DeleteEmployeeConfirmation />}
                                    onConfirm={wrapAction(onDeactivateEmployee)}
                                    okText="Yes"
                                    cancelText="No"
                                    overlayStyle={{ width: '400px' }}
                                    placement="topLeft"
                                >
                                    <Button
                                        data-testid="deactivate-employee-button"
                                        danger
                                        disabled={deleting}
                                    >
                                        Deactivate employee
                                    </Button>
                                </Popconfirm>
                            </FormItem>
                        )}
                        {(userTypes.isPartnerAdmin || userTypes.isPartnerEmployee) && (
                            <FormItem name="deactivatePartner" wrapperCol={wrapperCol}>
                                <Popconfirm
                                    title={<DeletePartnerEmployeeConfirmation />}
                                    onConfirm={wrapAction(onDeactivatePartnerEmployee)}
                                    okText="Yes"
                                    cancelText="No"
                                    overlayStyle={{ width: '400px' }}
                                    placement="topLeft"
                                >
                                    <Button
                                        data-testid="deactivate-partner-employee-button"
                                        danger
                                        disabled={deleting}
                                    >
                                        Delete partner employee
                                    </Button>
                                </Popconfirm>
                            </FormItem>
                        )}
                        {!userTypes.isPartnerAdmin &&
                            !userTypes.isPartnerEmployee &&
                            !userTypes.isAdmin &&
                            !userTypes.isEmployee && (
                                <FormItem name="deactivatePartner" wrapperCol={wrapperCol}>
                                    <Popconfirm
                                        title={<DeleteUserConfirmation />}
                                        onConfirm={wrapAction(onDeactivateUser)}
                                        okText="Yes"
                                        cancelText="No"
                                        overlayStyle={{ width: '400px' }}
                                        placement="topLeft"
                                    >
                                        <Button
                                            data-testid="deactivate-user-button"
                                            danger
                                            disabled={deleting}
                                        >
                                            Delete user
                                        </Button>
                                    </Popconfirm>
                                </FormItem>
                            )}
                        <ButtonContainer>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={!isValid}
                                data-testid="save"
                            >
                                Save
                            </Button>
                        </ButtonContainer>
                    </Form>
                )
            }}
        </Formik>
    )
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

export default EditInformation
