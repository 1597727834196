import type { FC } from 'react'
import { Table, Card, Typography, Space, Tooltip } from 'antd'
import { useDispatch } from 'react-redux'
import type { Company } from 'types/deimos-company'
import { getEmojiFlag } from 'countries-list'
import { useNavigate } from 'react-router-dom'
import type { ColumnProps } from 'antd/lib/table'
import { actions as userActions } from 'store/modules/support/user/slice'
import getCompanyKycStatus from 'utils/get-company-kyc-status'
import { getUserTypes, getUserTypeDisplay, getUserName } from 'utils/user'
import { KycStatus } from 'types/styx'
import dayjs from 'packages/dayjs'
import { PageContentLayout, PointerContainer } from 'components/layout-containers'
import type { User } from 'types/employee'
import type { Partner } from 'types/partner-portal'

interface Props {
    companies?: Company[]
    partners?: Partner[]
    users?: User[]
}

const { Text } = Typography

const companyColumns: ColumnProps<Company>[] = [
    {
        title: 'Country',
        dataIndex: 'address',
        render: (address) => (
            <Space>
                <Text>{getEmojiFlag(address.country)}</Text>
                <Text>{address.country}</Text>
            </Space>
        ),
    },
    {
        title: 'Company name',
        dataIndex: 'name',
        render: (companyName, { styxId }) => (
            <Text disabled={!styxId}>
                {companyName}
                {!styxId && ' – No Styx ID found, click to re-register in styx'}
            </Text>
        ),
    },
    {
        title: 'Company ID',
        dataIndex: 'id',
        onCell: (_) => ({ onClick: (e) => e.stopPropagation() }),
        render: (companyId) => <Text copyable>{companyId}</Text>,
    },
    {
        title: 'Organization ID',
        dataIndex: 'organizationId',
        render: (organizationId) =>
            organizationId ? <Text copyable>{organizationId}</Text> : <Text>-</Text>,
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (createdAt) => dayjs(createdAt).format('lll'),
        sorter: (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
    },
]

const partnerColumns: ColumnProps<Partner>[] = [
    {
        title: 'Country',
        dataIndex: 'address',
        render: (address) => (
            <Space>
                <Text>{getEmojiFlag(address.country)}</Text>
                <Text>{address.country}</Text>
            </Space>
        ),
    },
    {
        title: 'Partner name',
        dataIndex: 'name',
    },
    {
        title: 'Pleo Customer',
        dataIndex: 'ownCompanyId',
        render: (ownCompanyId) => (ownCompanyId ? 'Yes' : 'No'),
    },
    {
        title: 'Partner ID',
        dataIndex: 'id',
        onCell: (_) => ({ onClick: (e) => e.stopPropagation() }),
        render: (partnerId) => <Text copyable>{partnerId}</Text>,
    },
    {
        title: 'Created',
        dataIndex: 'createdAt',
        render: (createdAt) => dayjs(createdAt).format('lll'),
        sorter: (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
    },
]

const userColumns: ColumnProps<User>[] = [
    {
        title: 'Name',
        render: (_: string, user: User) => getUserName(user),
        sorter: (a: User, b: User) => getUserName(a).localeCompare(getUserName(b)),
    },
    {
        title: 'Role',
        dataIndex: 'admin',
        filters: ['Employee', 'Admin'].map((value = '') => ({
            value,
            text: value,
        })),
        onFilter: (value, user: User) => {
            const userTypes = getUserTypes(user)
            return (
                (userTypes.isAdmin && value === 'Admin') ||
                (!userTypes.isAdmin && value === 'Employee')
            )
        },
        render: (_: boolean, user: User) => getUserTypeDisplay(user),
    },
    {
        title: 'Email',
        dataIndex: 'email',
    },
    {
        title: 'User ID',
        dataIndex: 'id',
        onCell: (_) => ({ onClick: (e) => e.stopPropagation() }),
        render: (userId) => <Text copyable>{userId}</Text>,
    },
    {
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (createdAt) => dayjs(createdAt).format('lll'),
        sorter: (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf(),
    },
]

const Search: FC<React.PropsWithChildren<Props>> = ({ companies, partners, users }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    if (companyColumns[2].title !== 'KYC Status') {
        companyColumns.splice(2, 0, {
            title: 'KYC Status',
            dataIndex: 'status',
            render: (_, company) => getCompanyKycStatus(company).toUpperCase(),
            filters: Object.values(KycStatus).map((status) => ({
                text: status.toUpperCase(),
                value: status,
            })),
            onFilter: (status, company) => status === getCompanyKycStatus(company).toUpperCase(),
            filterMultiple: true,
        })
    }

    const onCompanyClick = (company: Company, mouseEvent: React.MouseEvent) => {
        if (mouseEvent.ctrlKey || mouseEvent.metaKey) {
            window.open(`/customer-success/companies/${company.id}`)
        } else {
            navigate(`/customer-success/companies/${company.id}`)
        }
    }

    const onUserClick = (user: User, mouseEvent: React.MouseEvent) => {
        dispatch(userActions.updatedUser(user))
        if (mouseEvent.ctrlKey || mouseEvent.metaKey) {
            window.open(`/customer-success/users/${user.id}`)
        } else {
            navigate(`/customer-success/users/${user.id}`)
        }
    }

    const onPartnerClick = (partner: Partner, mouseEvent: React.MouseEvent) => {
        if (mouseEvent.ctrlKey || mouseEvent.metaKey) {
            window.open(`/customer-success/partners/${partner.id}`)
        } else {
            navigate(`/customer-success/partners/${partner.id}`)
        }
    }

    const onCompany360Click = (company: Company, mouseEvent: React.MouseEvent) => {
        if (mouseEvent.ctrlKey || mouseEvent.metaKey) {
            window.open(`/customer-success/company-360/${company.id}`)
        } else {
            navigate(`/customer-success/company-360/${company.id}`)
        }
    }

    const usersTitle = (
        <Tooltip title="This is likely not an exhaustive list">Users (up to 10 results)</Tooltip>
    )

    return (
        <PageContentLayout>
            <Card title="Companies">
                <PointerContainer>
                    <Table
                        loading={!companies}
                        rowKey="id"
                        dataSource={companies}
                        columns={companyColumns}
                        onRow={(record) => ({
                            onClick: (mouseEvent) => onCompanyClick(record, mouseEvent),
                        })}
                    />
                </PointerContainer>
            </Card>
            <Card title={usersTitle}>
                <PointerContainer>
                    <Table
                        rowKey="id"
                        dataSource={users}
                        columns={userColumns}
                        onRow={(record) => ({
                            onClick: (mouseEvent) => onUserClick(record, mouseEvent),
                        })}
                    />
                </PointerContainer>
            </Card>
            <Card title="Partners">
                <PointerContainer>
                    <Table
                        rowKey="id"
                        dataSource={partners}
                        columns={partnerColumns}
                        onRow={(record) => ({
                            onClick: (mouseEvent) => onPartnerClick(record, mouseEvent),
                        })}
                    />
                </PointerContainer>
            </Card>
            <Card title="Company 360">
                <PointerContainer>
                    <Table
                        loading={!companies}
                        rowKey="id"
                        dataSource={companies}
                        columns={companyColumns}
                        onRow={(record) => ({
                            onClick: (mouseEvent) => onCompany360Click(record, mouseEvent),
                        })}
                    />
                </PointerContainer>
            </Card>
        </PageContentLayout>
    )
}

export default Search
