import useSWR from 'swr'
import { notification } from 'antd'

import {
    ProofOfFundsRefundStatus,
    ProofOfFundsRejectionReason,
    Status,
    Transaction,
} from 'types/proof-of-funds'

import request, { fetcher } from './request'
import { default as cupidRequest, fetcher as cupidFetcher } from 'services/cupid/request'
import type { BankAccountInfo, ProofOfFunds } from '@pleo-io/deimos'

export const PAGE_LIMIT = 100

export const updateProofOfFunds = (
    companyId: string,
    id: string,
    status: Status
): Promise<Response> => {
    return request().patch(`rest/v1/styx/companies/${companyId}/proof-of-funds/${id}`, {
        json: { status },
    })
}

export const cancelWalletLoad = async (nextStatus: Status, invoiceId: string) => {
    if (nextStatus === Status.REJECTED) {
        try {
            await request().delete(`wallet-management/rest/v4/invoices/${invoiceId}/loads`)
            notification.success({
                message: `No further action is required`,
                description: `The source has been rejected and all scheduled wallet-loads from this source have been cancelled.`,
            })
        } catch (e) {
            notification.info({
                message: (e as Error).message,
                description: `The source of has been rejected, but associated wallet loads must be manually unloaded.`,
                // set duration to zero so that manual dismissal is required
                duration: 0,
            })
        }
    }
}

const proofOfFundsByStatusFetcher = (
    url: string,
    status: Status,
    page: number,
    refundStatus: ProofOfFundsRefundStatus
) => {
    let searchParams: any = { status, page, limit: PAGE_LIMIT }
    if (refundStatus) {
        searchParams = { status, refundStatus, page, limit: PAGE_LIMIT }
    }
    return request()
        .get(url, {
            searchParams: searchParams,
        })
        .then((res) => res.json())
}

export interface RejectRefund {
    bankAccountInfo?: BankAccountInfo
    rejectionReason?: ProofOfFundsRejectionReason
    note?: string
    paymentPartyInfo: { country: string } | null
}

export const proofOfFundsRejectRefund = async (pofId: string, refundDetails: RejectRefund) => {
    const url = `rest/v2/proof-of-funds/${pofId}/reject-refund`
    return request().post(url, {
        json: refundDetails,
    })
}

export interface ProofOfFundsResponse {
    transactions: Transaction[]
    total: number
}

export const useGetProofOfFundsByStatus = (
    status: Status,
    page?: number,
    refundStatus?: ProofOfFundsRefundStatus
) => {
    const response = useSWR<ProofOfFundsResponse, Error>(
        ['rest/v1/styx/proof-of-funds', status, page ? page - 1 : 0, refundStatus],
        proofOfFundsByStatusFetcher
    )

    const transactions = response.data?.transactions
    const totalTransactions = response.data?.total

    const updateTransactionStatus = async (transaction: Transaction, nextStatus: Status) => {
        await updateProofOfFunds(transaction.companyId, transaction.id, nextStatus)
        await cancelWalletLoad(nextStatus, transaction.invoiceId)

        return response.mutate()
    }

    const rejectRefund = async (pofId: string, refundDetails: RejectRefund) => {
        const refundResponse = await proofOfFundsRejectRefund(pofId, refundDetails)

        await response.mutate()

        return refundResponse
    }

    return {
        ...response,
        transactions,
        totalTransactions,
        mutations: { updateTransactionStatus, rejectRefund },
    }
}

export const useGetCompanyProofOfFunds = (companyId: string | null) =>
    useSWR(() => companyId && `rest/v1/styx/companies/${companyId}/proof-of-funds`, fetcher)

export const useGetLatestProofOfFunds = (companyId: string | null) => {
    const result = useSWR(
        companyId ? `rest/v1/styx/companies/${companyId}/proof-of-funds/latest-approved` : null,
        fetcher,
        { shouldRetryOnError: false }
    )
    return {
        ...result,
        senderBank: result.data?.senderBank,
        senderInformation: result.data?.senderInformation,
    }
}

export const useGetLatestProofOfFundsPerSourceByStatus = (companyId: string, status: Status) =>
    useSWR<ProofOfFunds[], Error>(
        () =>
            companyId &&
            `rest/v1/styx/companies/${companyId}/proof-of-funds/latest-per-source/${status}`,
        fetcher
    )

export interface FraudCheckRecord {
    id: string
    companyId: string
    status: string
    createdAt: string
}

export const useFraudCheckRecords = () => {
    const result = useSWR<FraudCheckRecord[]>(`rest/v4/fraud-checks`, cupidFetcher)

    const approveFraudCheckRecord = async (fraudCheckId: string) => {
        await cupidRequest().put(`rest/v4/fraud-checks/${fraudCheckId}`, {
            json: { status: 'PASS' },
        })

        result.mutate()
    }

    return {
        ...result,
        mutations: {
            approveFraudCheckRecord,
        },
    }
}
