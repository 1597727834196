import { Card, Form, Input, Radio, Space } from 'antd'
import { useEditAppContext } from '../contexts/view-edit-app-context'
import { AppAuthenticationMethod } from './auth-method-input'
import {
    AppCategoryInput,
    AppNameInput,
    AppSlugInput,
    AppTypeInput,
    FormTextInput,
} from './shared-app-form-inputs'

export const EditAppForm = () => (
    <Space size="middle" direction="vertical" style={{ width: '100%' }}>
        <EditAppForm.BasicInformation />
        <EditAppForm.AppConfiguration />
        <EditAppForm.MarketplaceListing />
        {/* <EditAppForm.ClientConfiguration /> */}
        <EditAppForm.ClientConfigurationReduced />
        <EditAppForm.Debug />
    </Space>
)

// #region Basic information

const BasicInformation = () => (
    <Card title="Basic information">
        <Space direction="vertical" style={{ marginTop: '16px', width: '100%' }} size="small">
            <BasicInformation.NameInput />
            <BasicInformation.SlugInput
                alertMessage="Slug is used in the URL of the app listing. Changing it will break any existing links pointing to this application."
                alertType="warning"
            />
            <BasicInformation.TypeInput />
            <BasicInformation.CategoryInput />
            <BasicInformation.AppId />
        </Space>
    </Card>
)
EditAppForm.BasicInformation = BasicInformation

BasicInformation.AppId = function AppId() {
    return <FormTextInput label="App ID" name="id" readOnly />
}
BasicInformation.NameInput = AppNameInput
BasicInformation.SlugInput = AppSlugInput
BasicInformation.TypeInput = AppTypeInput
BasicInformation.CategoryInput = AppCategoryInput

// #endregion Basic information

// #region App configuration
const AppConfiguration = () => (
    <Card title="App configuration">
        <AppConfiguration.ActivationCallback />
        <AppConfiguration.SupportedCountries />
        <AppConfiguration.EnabledByFeatures />
    </Card>
)
EditAppForm.AppConfiguration = AppConfiguration

const ActivationCallbackInput = () => (
    <Form.Item label="Activation callback" name="activationCallback">
        <Radio.Group>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
        </Radio.Group>
    </Form.Item>
)
AppConfiguration.ActivationCallback = ActivationCallbackInput

AppConfiguration.SupportedCountries = function SupportedCountriesInput() {
    return (
        <FormTextInput
            label="Supported countries"
            name="supportedCountries"
            description="Comma-separated list of supported countries ISO code"
            placeholder="ES,DK,FR"
        />
    )
}

AppConfiguration.EnabledByFeatures = function EnabledByFeaturesInput() {
    return (
        <FormTextInput
            label="Enabled by features"
            name="enabledByFeatures"
            description="Comma-separated list of Janus feature entitlements that grant access to the app"
            placeholder="PEOPLE_MANAGEMENT_APPS"
        />
    )
}
// #endregion App configuration

// #region Marketplace listing
const MarketplaceListing = () => (
    <Card title="Marketplace listing">
        <MarketplaceListing.DescriptionInput />
        <MarketplaceListing.LogoInput />
        <MarketplaceListing.DeveloperNameInput />
        <MarketplaceListing.TargetSystemUrlInput />
        <MarketplaceListing.InitiationUrlInput />
        <MarketplaceListing.SettingsUrlInput />
        <MarketplaceListing.TagsInput />
    </Card>
)
EditAppForm.MarketplaceListing = MarketplaceListing

MarketplaceListing.DescriptionInput = function DescriptionInput() {
    return (
        <FormTextInput
            label="Description"
            name="description"
            description="A short description of the app that will be displayed in the marketplace"
        />
    )
}
MarketplaceListing.TargetSystemUrlInput = function TargetSystemUrlInput() {
    return <FormTextInput label="Target System URL" name="targetSystemUrl" />
}
MarketplaceListing.InitiationUrlInput = function InitiationUrlInput() {
    return <FormTextInput label="Initiation URL" name="initiationUrl" />
}
MarketplaceListing.SettingsUrlInput = function SettingsUrlInput() {
    return <FormTextInput label="Settings URL" name="settingsUrl" />
}
MarketplaceListing.DeveloperNameInput = function DeveloperNameInput() {
    return <FormTextInput label="Developer name" name="developerName" />
}
MarketplaceListing.TagsInput = function TagsInput() {
    return (
        <FormTextInput label="Tags" name="tags" description="Comma separated tags for filtering" />
    )
}
// #endregion Marketplace listing

// #region Client configuration
const ClientConfigurationReduced = () => (
    <Card title="Client configuration">
        <FormTextInput label="Auth method" name="authenticationMethod" readOnly />
    </Card>
)
EditAppForm.ClientConfigurationReduced = ClientConfigurationReduced

const ClientConfiguration = () => {
    const app = useEditAppContext()

    return (
        <Card title="Client configuration">
            <ClientConfiguration.AuthenticationMethod initialValue={app.authenticationMethod} />
        </Card>
    )
}
EditAppForm.ClientConfiguration = ClientConfiguration
ClientConfiguration.AuthenticationMethod = AppAuthenticationMethod

const LogoInput = () => (
    <Form.Item label="Logo" name="logo">
        <Input type="file" />
    </Form.Item>
)
MarketplaceListing.LogoInput = LogoInput
// #endregion Client configuration

// #region Debug
const Debug = () => {
    const app = useEditAppContext()
    return (
        <Card title="Debug">
            <pre>{JSON.stringify(app, null, 4)}</pre>
        </Card>
    )
}
EditAppForm.Debug = Debug
// #endregion Debug
