import { Avatar, Space, TableColumnProps, Tag, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'

import type { OddList as OddListType } from '../index.bff'
import { AssignToMeButton } from '../components/assign-to-me'
import { UserAddOutlined, ClockCircleOutlined, FlagOutlined } from '@ant-design/icons'
import { RiskRatingTag } from 'components/risk-rating-tag/risk-rating-tag'
import { getEmojiFlag } from 'countries-list'
import { formatDate } from 'packages/dates/dates'
import { capitalizeFirstLetter } from 'utils/strings'
import { StatusTag } from './status-tag'

const { Text } = Typography

export const oddStartedColumn: TableColumnProps<OddListType> = {
    title: 'ODD Started',
    dataIndex: 'oddStartDate',
    key: 'oddStartDate',
    render: (_, oddCase) => <OddStartedCell {...oddCase} />,
}

const OddStartedCell = ({ assignedTo, id }: OddListType) => {
    const caseHandlerInitials = assignedTo
        ? `${assignedTo.firstName} ${assignedTo.lastName}`
              .split(' ')
              .map((name) => name[0])
              .join('')
              .toUpperCase()
        : ''

    return (
        <Space>
            <Tooltip title={`${assignedTo?.firstName} ${assignedTo?.lastName}`}>
                <Avatar
                    shape="square"
                    size="small"
                    icon={assignedTo ? undefined : <UserAddOutlined />}
                >
                    {caseHandlerInitials}
                </Avatar>
            </Tooltip>
            {assignedTo ? <Text>{formatDate(assignedTo?.at)}</Text> : <AssignToMeButton id={id} />}
        </Space>
    )
}

export const dueDateColumn: TableColumnProps<OddListType> = {
    title: 'Due Date',
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (dueDate) => {
        const getType = () => {
            if (dueDate === null) return undefined
            const today = dayjs()
            const dueDateDayJs = dayjs(dueDate)
            if (today.isAfter(dueDateDayJs)) return 'danger'
            if (today.isAfter(dueDateDayJs.subtract(1, 'w'))) return 'warning'
        }
        const type = getType()
        return <Text type={type}>{formatDate(dueDate)}</Text>
    },
}

export const companyColumn: TableColumnProps<OddListType> = {
    title: 'Company',
    dataIndex: 'country',
    key: 'company',
    render: (_, oddCase) => {
        const { metadata, entityId } = oddCase
        const companyId = entityId
        const {
            companyName,
            companyRegistrationNumber: registrationNumber,
            companyCountryCode,
        } = metadata

        return (
            <Tooltip
                title={
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <strong>Company ID</strong>
                        <span style={{ whiteSpace: 'pre' }}>{companyId}</span>
                    </div>
                }
                overlayStyle={{ minWidth: 'max-content' }}
            >
                <Space direction="vertical" size={'small'}>
                    <Text>{companyName}</Text>
                    <Space size={'small'}>
                        <Tag>
                            {getEmojiFlag(companyCountryCode)} {companyCountryCode}
                        </Tag>
                        <Text type="secondary">{registrationNumber}</Text>
                    </Space>
                </Space>
            </Tooltip>
        )
    },
}

export const previousRiskRatingColumn: TableColumnProps<OddListType> = {
    title: 'Previous Risk Score',
    dataIndex: 'previousRiskScore',
    key: 'previousRiskScore',
    width: 100,
    render: (_, oddCase) => {
        const { metadata } = oddCase
        const { previousRiskScore } = metadata
        return <RiskRatingTag riskRating={previousRiskScore} />
    },
}

export const preliminaryRiskRatingColumn: TableColumnProps<OddListType> = {
    title: 'Preliminary Risk Score',
    dataIndex: 'preliminaryRiskScore',
    key: 'preliminaryRiskScore',
    render: (_, oddCase) => {
        const { metadata } = oddCase
        const { preliminaryRiskScore } = metadata
        return <RiskRatingTag riskRating={preliminaryRiskScore} isDraft />
    },
    width: 50,
}

export const previousKycColumn: TableColumnProps<OddListType> = {
    title: 'Previous KYC',
    dataIndex: 'previousKycScore',
    key: 'previousKycScore',
    render: (_, oddCase) => {
        const { metadata } = oddCase
        const { previousKycScore } = metadata
        return (
            <Space direction="vertical">
                <Tag style={{ fontSize: '0.75rem' }}>{previousKycScore}</Tag>
            </Space>
        )
    },
    width: 75,
}

export const oddTriggerColumn: TableColumnProps<OddListType> = {
    title: 'Trigger',
    dataIndex: 'triggerType',
    key: 'triggerType',
    render: (triggerType) => {
        switch (triggerType) {
            case 'TIME':
                return (
                    <Tag icon={<ClockCircleOutlined />} style={{ fontSize: '0.75rem' }}>
                        Time
                    </Tag>
                )
            case 'EVENT':
                return (
                    <Tag icon={<FlagOutlined />} style={{ fontSize: '0.75rem' }}>
                        Event
                    </Tag>
                )
            default:
                return (
                    <Tag icon={<FlagOutlined />} style={{ fontSize: '0.75rem' }}>
                        {triggerType && capitalizeFirstLetter(triggerType)}
                    </Tag>
                )
        }
    },
}

export const oddStatusColumn: TableColumnProps<OddListType> = {
    title: 'Status',
    dataIndex: 'state',
    key: 'state',
    render: (state) => <StatusTag status={state} />,
}

export const riskRatingColumn: TableColumnProps<OddListType> = {
    title: 'Risk Rating',
    dataIndex: 'riskRating',
    key: 'riskRating',
    width: 100,
    render: (riskRating) => <RiskRatingTag riskRating={riskRating} />,
}
