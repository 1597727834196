import ky from 'ky/umd'

import config from 'config'

import { hooks } from './request-hooks'

const endpoints = config.endpoints

let request = ky.extend({ hooks })

export const setRequestToken = (adminToken: string) => {
    request = ky.extend({
        timeout: 60000,
        headers: {
            Authorization: `Bearer ${adminToken}`,
        },
        hooks,
    })
}

// getter functions to grab mutated request instance with bearer token set.
export const deimosRequest = () => request.extend({ prefixUrl: endpoints.api })

export const pandoraRequest = () => request.extend({ prefixUrl: endpoints.pandora })

export const calypsoRequest = () => request.extend({ prefixUrl: endpoints.calypso })

export const calibanRequest = () => request.extend({ prefixUrl: endpoints.caliban })

export const cadmusRequest = () => request.extend({ prefixUrl: endpoints.api })

export const arielRequest = () => request.extend({ prefixUrl: endpoints.ariel })

export const styxRequest = () => request.extend({ prefixUrl: endpoints.styx })

export const themisRequest = () => request.extend({ prefixUrl: endpoints.themis })

export const hydraRequest = () => request.extend({ prefixUrl: endpoints.hydra })

export const beyondRequest = () => request.extend({ prefixUrl: endpoints.beyond })

export const dionysusRequest = () =>
    request.extend({
        prefixUrl: endpoints.dionysus,
        // Requests to generate demo accounts can take over 2mins
        timeout: 180000,
    })

export const kerberosRequest = () =>
    request.extend({
        prefixUrl: endpoints.auth,
    })

export const phobosRequest = () =>
    request.extend({
        prefixUrl: endpoints.phobos,
    })

export const oberonRequest = () =>
    request.extend({
        prefixUrl: endpoints.api,
    })

export const telestoRequest = () =>
    request.extend({
        prefixUrl: endpoints.telesto,
    })

export const eireneRequest = () =>
    request.extend({
        prefixUrl: endpoints.eirene,
    })

export const skollRequest = () =>
    request.extend({
        prefixUrl: endpoints.skoll,
    })

export const tarvosRequest = () =>
    request.extend({
        prefixUrl: endpoints.api,
    })

export const cupidRequest = () =>
    request.extend({
        prefixUrl: endpoints.cupid,
    })

export const callistoRequest = () =>
    request.extend({
        prefixUrl: endpoints.callisto,
    })

export const exportRequest = () =>
    request.extend({
        prefixUrl: endpoints.exportDispatcher,
    })

export const prosperoRequest = () =>
    request.extend({
        prefixUrl: endpoints.prospero,
    })

export const phoebeRequest = () =>
    request.extend({
        prefixUrl: endpoints.phoebe,
    })

export const customervulnerabilitytrackerRequest = () =>
    request.extend({
        prefixUrl: endpoints.customervulnerabilitytracker,
    })

// Helper function to create SWR fetcher using the above "requests". See: https://swr.vercel.app/docs/data-fetching
export const buildFetcher = (requestGetter: () => typeof request) => (url: string) =>
    requestGetter()
        .get(url)
        .then((res) => {
            if (res.status === 204) {
                return res.text()
            }
            return res.json()
        })
