import { Button, message } from 'antd'
import { bff } from '../bff-hooks'

type AssignToMeButtonProps = {
    id: string
}

const successContent = () => 'Case successfully assigned to you'

export const AssignToMeButton = ({ id }: AssignToMeButtonProps) => {
    const { mutateAsync: assignToMe } = bff.assignCaseToMe.assignCaseToMe.useMutation()

    const buttonText = 'Assign to me'

    return (
        <Button
            style={{ padding: 0 }}
            type="link"
            onClick={async (e) => {
                e.stopPropagation()
                try {
                    await assignToMe({ caseId: id }).then(() => message.success(successContent()))
                } catch (error) {
                    message.error('Failed to assign case')
                }
            }}
        >
            {buttonText}
        </Button>
    )
}
