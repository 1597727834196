import { Button, Card, Checkbox, Tooltip, Typography } from 'antd'
import styled from 'styled-components'

import { bff } from '../bff-hooks'
import { useOddDetailContext } from '../context'
import type { Check } from './checks.bff'
import { formatTimestamp } from 'packages/dates/dates'

const { Text } = Typography

export const CheckList = () => {
    const { caseId, isLocked, conclusionModal, offboardedModal } = useOddDetailContext()
    const { data } = bff.checks.getChecks.useQuery({ caseId })

    return (
        <>
            <Card
                title="Tasks"
                style={{ width: '25%', position: 'sticky', top: 16 }}
                bodyStyle={{ padding: '8px 24px' }}
                actions={[
                    <Button
                        key="offboarded"
                        disabled={!data?.isReadyToOffboard || isLocked}
                        onClick={offboardedModal.open}
                    >
                        Offboarded
                    </Button>,
                    <Button
                        key="conclue"
                        type="primary"
                        disabled={!data?.isReadyToConclude || isLocked}
                        onClick={conclusionModal.open}
                    >
                        Conclude ODD
                    </Button>,
                ]}
            >
                <div>
                    {data?.checks.map((check) => <CheckItem key={check.key} check={check} />)}
                </div>
            </Card>
        </>
    )
}

const CheckItem = ({ check }: { check: Check }) => {
    const { caseId, isLocked } = useOddDetailContext()
    const { mutateAsync: updateCheck, isLoading } = bff.checks.updateCheck.useMutation()

    const handleCheckChange = (e: any) => {
        updateCheck({ caseId, checkKey: check.key, complete: e.target.checked })
    }

    return (
        <CheckItemWrapper>
            <Tooltip
                title={
                    check.completed
                        ? `Marked as complete by ${check.completed.createdBy.firstName} ${check.completed.createdBy.lastName} on ${formatTimestamp(check.completed.createdBy.at)}.`
                        : ''
                }
            >
                <Checkbox
                    disabled={isLoading || isLocked}
                    checked={check.completed !== undefined}
                    onChange={handleCheckChange}
                >
                    <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
                        <Text delete={check.completed !== undefined}>{check.name}</Text>
                        <Text
                            style={{ fontSize: '0.875em' }}
                            delete={check.completed !== undefined}
                            type="secondary"
                        >
                            {check.description}
                        </Text>
                    </div>
                </Checkbox>
            </Tooltip>
        </CheckItemWrapper>
    )
}

const CheckItemWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0;

    &:not(:last-child) {
        border-bottom: 1px solid #f0f0f0;
    }
`
