import styled from 'styled-components'

import { PageContentLayout } from 'components/layout-containers'

import { OddDetailProvider } from '../context'

import { Header } from '../components/page-header'
import { CheckList } from '../components/checks'
import { Evidence } from '../components/evidence'
import { ConclusionModal, HistoryModal, OffboardedModal } from '../components/modals'

export const OddDetailPage = () => {
    return (
        <OddDetailProvider>
            <PageContentLayout>
                <Header />
                <Container>
                    <CheckList />
                    <Evidence />
                </Container>
            </PageContentLayout>
            <OffboardedModal />
            <ConclusionModal />
            <HistoryModal />
        </OddDetailProvider>
    )
}

const Container = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
`
