import type { EmployeeResponseModel } from 'bff/moons/generated/oberon-v1'
import type { PartnerBookkeeper } from 'bff/moons/static/kerberos-v1'
import type { Role, Bookkeeper, BookkeeperDisplayedRole, Employee } from 'types/employee'

export const roleToDisplayedRole: { [key: string]: BookkeeperDisplayedRole } = {
    partner: 'partner',
    'bookkeeper-extended': 'extended',
    'bookkeeper-basic': 'basic',
    bookkeeper: 'basic',
    '': 'basic',
}

export const mapPartnerBookkeepersForDisplay = (
    partnerBookkeepers: PartnerBookkeeper[]
): Bookkeeper[] =>
    partnerBookkeepers?.map((partnerBookkeeper) => ({
        ...partnerBookkeeper,
        displayedRole: 'partner',
    }))

export const mapBookkeepersForDisplay = (employees: EmployeeResponseModel[]): Bookkeeper[] =>
    employees.map((employee) => ({
        userId: employee.userId ?? '',
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        displayedRole: roleToDisplayedRole[employee.role ?? ''],
    }))

export const getBookkeeperInfo = (bookkeeper: Bookkeeper) => {
    const bookkeeperName = `${bookkeeper.firstName || ''}${
        bookkeeper.lastName ? ` ${bookkeeper.lastName}` : ''
    }`
    const bookkeeperInfo = `${bookkeeperName || bookkeeper.email}`
    return bookkeeperInfo
}

export const mapAdminsForDisplay = (admins: EmployeeResponseModel[]): Employee[] =>
    admins.map((admin) => ({
        id: admin.id,
        userId: admin.userId ?? '',
        firstName: admin.firstName,
        lastName: admin.lastName,
        email: admin.email,
        phone: admin.phone ?? '',
        createdAt: admin.createdAt,
        updatedAt: admin.updatedAt,
        role: admin.role as Role,
    }))
