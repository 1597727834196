import {
    Button,
    Collapse,
    Descriptions,
    Divider,
    List,
    notification,
    Space,
    Tag,
    Typography,
} from 'antd'
import { bff } from '../bff-hooks'
import { formatDate } from 'packages/dates/dates'
import { ShopOutlined, UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useOddDetailContext } from '../context'

const { Text } = Typography

export const Evidence = () => {
    const { companyId } = useOddDetailContext()
    const evidencePanels = getEvidencePanels(companyId)
    return (
        <Collapse style={{ width: '75%' }} defaultActiveKey={'case-overview'}>
            {evidencePanels.map((panel) => (
                <Collapse.Panel
                    header={
                        <Text
                            style={{
                                fontSize: '16px',
                                display: 'inline-block',
                                padding: '3px 0',
                                fontWeight: 600,
                            }}
                        >
                            {panel.header}
                        </Text>
                    }
                    key={panel.key}
                    showArrow={false}
                >
                    {panel.render()}
                </Collapse.Panel>
            ))}
        </Collapse>
    )
}

const getEvidencePanels = (companyId?: string) => [
    {
        header: 'Case Overview',
        key: 'case-overview',
        render: () => {
            const { data } = bff.evidence.getCaseOverview.useQuery(
                { companyId },
                { enabled: !!companyId }
            )

            const handleRequestInformation = () => {
                // TODO: Implement
                notification.error({ message: 'Not implemented' })
            }

            return (
                <Space direction="vertical" style={{ width: '100%' }}>
                    <List
                        dataSource={data}
                        style={{ width: '100%' }}
                        header={
                            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text strong>Missing KYC Information</Text>
                                <Button onClick={handleRequestInformation}>
                                    Request information
                                </Button>
                            </Space>
                        }
                        renderItem={(item) => (
                            <List.Item
                                style={{
                                    width: '100%',
                                }}
                            >
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <Text style={{ marginRight: '0.5rem', width: '30%' }}>
                                        {item?.id === 'company' ? (
                                            <ShopOutlined style={{ color: '#fadb14' }} />
                                        ) : (
                                            <UserOutlined style={{ color: '#1677ff' }} />
                                        )}{' '}
                                        {item?.label}
                                    </Text>
                                    <Space
                                        style={{
                                            width: '70%',
                                            display: 'flex',
                                            flexFlow: 'row wrap',
                                        }}
                                    >
                                        {item?.missingInformation.map((info) => (
                                            <Tag key={info} color="orange">
                                                {info}
                                            </Tag>
                                        ))}
                                    </Space>
                                </div>
                            </List.Item>
                        )}
                    />
                    <Divider style={{ margin: '4px 0' }} />
                    <Text strong>Company Spending</Text>
                    <Link target="_blank" to={`/compliance/companies/${companyId}`}>
                        See Company page
                    </Link>
                </Space>
            )
        },
    },
    {
        header: 'Company',
        key: 'company',
        render: () => {
            const { data } = bff.evidence.getCompany.useQuery({ companyId })
            return (
                <Descriptions column={2}>
                    <Descriptions.Item label="Registration Number">
                        {data?.registrationNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Trading Name">{data?.tradingName}</Descriptions.Item>
                    <Descriptions.Item label="Domain">{data?.domainName}</Descriptions.Item>
                    <Descriptions.Item label="CACP">{data?.cacp}</Descriptions.Item>
                    <Descriptions.Item label="NACE">{data?.nace}</Descriptions.Item>
                    <Descriptions.Item label="Company Size">{data?.companySize}</Descriptions.Item>
                    <Descriptions.Item label="Incorporation Date">
                        {formatDate(data?.incorporationDate)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Incorporation Type">
                        {data?.incorporationType}
                    </Descriptions.Item>
                </Descriptions>
            )
        },
    },
    {
        header: 'Employees',
        key: 'employees',
        render: () => (
            <Link target="_blank" to={`/compliance/companies/${companyId}`}>
                See Company page
            </Link>
        ),
    },
    {
        header: 'Company Refresh',
        key: 'company-refresh',
        render: () => (
            <Link target="_blank" to={`/compliance/companies/${companyId}`}>
                See Company page
            </Link>
        ),
    },
    {
        header: 'Documents',
        key: 'documents',
        render: () => (
            <Link target="_blank" to={`/compliance/companies/${companyId}`}>
                See Company page
            </Link>
        ),
    },
    {
        header: 'People & Entities',
        key: 'people-entities',
        render: () => (
            <Link target="_blank" to={`/compliance/companies/${companyId}`}>
                See Company page
            </Link>
        ),
    },
    {
        header: 'Ownership Structure',
        key: 'ownership-structure',
        render: () => (
            <Link target="_blank" to={`/compliance/companies/${companyId}`}>
                See Company page
            </Link>
        ),
    },
    {
        header: 'Risk Asessment',
        key: 'risk-assessment',
        render: () => (
            <Link target="_blank" to={`/compliance/companies/${companyId}`}>
                See Company page
            </Link>
        ),
    },
    {
        header: 'Transactions Overview',
        key: 'transactions-overview',
        render: () => (
            <Link target="_blank" to={`/compliance/companies/${companyId}`}>
                See Company page
            </Link>
        ),
    },
    {
        header: 'Customer Communication',
        key: 'customer-communication',
        render: () => (
            <Link target="_blank" to={`/compliance/companies/${companyId}`}>
                See Company page
            </Link>
        ),
    },
]
