import { Breadcrumb, Card, Pagination } from 'antd'
import { Link } from 'react-router-dom'
import { PageContentLayout } from 'components/layout-containers'
import { OddTable } from '../components/table'
import { FilterBar } from '../components/filters'
import { useFilters, oddListTabItems, DEFAULT_PAGE_SIZE } from '../components/utils'

export const OddListPage = () => {
    const {
        filters,
        data,
        activeTab,
        updateActiveTab,
        updateUrl,
        clearParams,
        updateSearchParams,
    } = useFilters()

    const isDisplayingClosed = activeTab === 'oddCompleted'

    return (
        <PageContentLayout>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to={'/compliance'}>Compliance</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to={'/compliance/odd'}>Ongoing Due Diligence</Link>
                </Breadcrumb.Item>
            </Breadcrumb>
            <Card
                title="Ongoing Due Diligence (ODD)"
                tabList={oddListTabItems}
                onTabChange={(key) => updateActiveTab(key)}
                activeTabKey={activeTab}
                bordered={false}
            >
                <FilterBar
                    activeTab={activeTab}
                    updateUrl={updateUrl}
                    filters={filters}
                    updateSearchParams={updateSearchParams}
                    clearParams={clearParams}
                />
                <OddTable data={data?.data} isDisplayingClosed={isDisplayingClosed} />
                <Pagination
                    style={{ marginTop: '20px', textAlign: 'right' }}
                    defaultCurrent={1}
                    current={
                        (data?.pagination?.currentRequestPagination?.offset || 0) /
                            DEFAULT_PAGE_SIZE +
                        1
                    }
                    total={data?.pagination?.total || 0}
                    pageSize={DEFAULT_PAGE_SIZE}
                    showSizeChanger={false}
                    onChange={(page) =>
                        updateSearchParams({ offset: (page - 1) * DEFAULT_PAGE_SIZE })
                    }
                />
            </Card>
        </PageContentLayout>
    )
}
