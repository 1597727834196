import { Alert, Card, Descriptions, Skeleton, Space, Statistic, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { formatDate } from 'packages/dates/dates'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import formatCurrency from 'utils/format-currency'
import AccountCardActions from './account-card-actions'
import type { inferProcedureOutput } from '@trpc/server'
import type { CreditRouter } from '../index.bff'
import type { Account } from 'bff/moons/generated/kalyke'
import { creditBff } from '../bff'
import AccountBalances from './account-balances'

const { Link } = Typography

type Employee = inferProcedureOutput<CreditRouter['credit']['getAccountDetails']>['employee']

interface AccountCardBodyProps {
    account: Account
    isMostRecent: boolean
}

export default function AccountDetails({ account, isMostRecent }: AccountCardBodyProps) {
    const navigate = useNavigate()
    const { id } = useParams()
    const { data, isLoading, isError } = creditBff.getAccountDetails.useQuery({
        companyId: id,
        userId: account.activatedBy,
    })

    if (account.accountType === 'RESERVE') {
        // it doesn't support Reserve yet: CRE-2739
        return <div>Coming soon...</div>
    }

    if (isLoading) {
        return <Skeleton active />
    }

    if (isError) {
        return <Alert message="There was an error fetching Account info." type="error" />
    }

    const handleActivatedByClick = (employee: Employee) => () => {
        navigate(`/customer-success/users/${employee.userId}`)
    }

    const activatedBy = data.employee

    return (
        <StyledContent data-testid={`account-${account.id}-content`}>
            <Space direction="vertical">
                <StyledStatisticsContainer>
                    <StyledStatisticCard>
                        <Statistic
                            title={'Loan limit'}
                            value={formatCurrency(account.principalAmount, account.currency)}
                        />
                    </StyledStatisticCard>
                    <StyledStatisticCard>
                        <Statistic title={'Interest rate'} value={`${account.interestRate}%`} />
                    </StyledStatisticCard>
                    {isMostRecent && <AccountBalances account={account} />}
                </StyledStatisticsContainer>
            </Space>
            <Descriptions column={1}>
                <Descriptions.Item label="Expiry date">
                    {formatDate(account.expiryDate)}
                </Descriptions.Item>
                <Descriptions.Item label="Charging interval">
                    {account.chargingDateInterval} days
                </Descriptions.Item>
                <Descriptions.Item label="Payment">
                    <Tag>{account.autoRepayment ? 'Auto' : 'Manual'}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label="Payment method">Coming soon...</Descriptions.Item>
                <Descriptions.Item label="Billing day">
                    {account.billingType === 'END_OF_MONTH'
                        ? formatDate(dayjs().endOf('month').toISOString())
                        : 'Unsupported'}{' '}
                </Descriptions.Item>
                <Descriptions.Item label="Activated by">
                    {activatedBy ? (
                        <Link onClick={handleActivatedByClick(activatedBy)}>
                            {activatedBy?.firstName} {activatedBy?.lastName}
                        </Link>
                    ) : (
                        'Unknown'
                    )}
                    {account.activatedAt && ` - ${formatDate(account.activatedAt)}`}
                </Descriptions.Item>
                <Descriptions.Item label="Repayment plan?">Coming soon...</Descriptions.Item>
            </Descriptions>
            <AccountCardActions />
        </StyledContent>
    )
}

const StyledContent = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
`

const StyledStatisticCard = styled(Card)`
    min-width: 150px;
`

const StyledStatisticsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    min-width: 400px;
`
