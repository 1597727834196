import styled from 'styled-components'
import { Table, TableColumnProps } from 'antd'
import { useNavigate } from 'react-router-dom'

import type { CaseOutput } from 'bff/moons/generated/case-manager'
import {
    companyColumn,
    previousRiskRatingColumn,
    preliminaryRiskRatingColumn,
    previousKycColumn,
    oddTriggerColumn,
    oddStatusColumn,
    oddStartedColumn,
    dueDateColumn,
    riskRatingColumn,
} from './columns'

export const OddTable = ({
    data,
    isDisplayingClosed,
}: {
    data?: CaseOutput[]
    isDisplayingClosed?: boolean
}) => {
    const navigate = useNavigate()

    const columns = isDisplayingClosed ? oddListTableColumnsClosed : oddListTableColumnsDefault
    const onRowClick = (id: string) => navigate(id)

    return (
        <TableStyleProvider>
            <Table
                size="middle"
                dataSource={data}
                columns={columns}
                onRow={({ id }) => ({ onClick: () => onRowClick(id) })}
                rowKey="id"
                pagination={false}
            />
        </TableStyleProvider>
    )
}

const oddListTableColumnsDefault: TableColumnProps<CaseOutput>[] = [
    companyColumn,
    previousRiskRatingColumn,
    preliminaryRiskRatingColumn,
    previousKycColumn,
    oddTriggerColumn,
    oddStatusColumn,
    oddStartedColumn,
    dueDateColumn,
]

const oddListTableColumnsClosed: TableColumnProps<CaseOutput>[] = [
    companyColumn,
    riskRatingColumn,
    previousKycColumn,
    oddTriggerColumn,
    oddStartedColumn,
    dueDateColumn,
]

export const TableStyleProvider = styled('div')`
    border-top: 1px solid #f0f0f0;

    .ant-pagination {
        margin-right: 16px;
    }

    & .ant-table-tbody tr {
        cursor: pointer;
    }
`
