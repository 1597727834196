import { useState, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    Alert,
    Button,
    Col,
    Divider,
    Input,
    message,
    Modal,
    Popconfirm,
    Result,
    Row,
    Select,
    Skeleton,
    Space,
    Table,
    Typography,
} from 'antd'
import {
    ExclamationCircleOutlined,
    LoadingOutlined,
    UserAddOutlined,
    UserDeleteOutlined,
} from '@ant-design/icons'
import type { ColumnProps } from 'antd/es/table'
import type { SortOrder } from 'antd/lib/table/interface'

import { ActionsContainer, BreakText, ContentContainer } from 'components/layout-containers'
import { DisconnectedPartnerClients } from 'components/disconnected-partner-clients/disconnected-partner-clients'
import { Partner, PartnerAccessLevel } from 'types/partner-portal'
import type { PartnerEmployee } from 'types/employee'
import { inputWidth } from 'theme/tokens'
import {
    getAssignedAndUnassignedBookkeepers,
    getPartnerEmployeeName,
} from 'utils/partner-employees'
import { connectPartnerClient, disconnectPartnerClient } from 'services/kerberos/partners'
import { useHasPermissions } from 'components/permission-guard/permission-guard'
import { useGetCompany } from 'services/deimos/companies'
import CompanyAddress from 'components/company-address/company-address'
import { useAssignBookkeeper, useUnassignBookkeeper } from './helpers'
import { useGetPartnerEmployees } from 'services/calypso/partner'
import { bff } from '../bff'
import type { PartnerCompany } from '../index.bff'
import dayjs from 'dayjs'

const { Search } = Input
const { confirm } = Modal
const { Option } = Select
const { Link, Text } = Typography

interface AssignPartnerBookkeeperProps {
    bookkeepers: PartnerEmployee[]
    company: PartnerCompany
    partnerId: string
}

const AssignPartnerBookkeeper = ({
    bookkeepers,
    company,
    partnerId,
}: AssignPartnerBookkeeperProps) => {
    const { loading, options, userId, assignBookkeeper, setUserId } = useAssignBookkeeper(
        partnerId,
        bookkeepers,
        company
    )

    const unselectBookkeeper = () => setUserId('')
    const selectBookkeeper = (bookkeeperUserId: string) => setUserId(bookkeeperUserId)

    if (!bookkeepers.length) {
        return null
    }

    return (
        <>
            <Select
                allowClear
                showArrow
                showSearch
                aria-label="Search Bookkeepers to assign"
                style={{ width: 220 }}
                placeholder="Search Bookkeeper"
                notFoundContent="No unassigned bookeepers found. Try another search"
                optionFilterProp="label"
                filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                loading={loading}
                options={options}
                onClear={unselectBookkeeper}
                onSelect={selectBookkeeper}
            />
            <Button
                aria-label="Assign Bookkeeper"
                type="primary"
                disabled={!userId}
                loading={loading}
                onClick={assignBookkeeper}
                icon={loading ? <LoadingOutlined /> : <UserAddOutlined />}
            >
                Assign
            </Button>
        </>
    )
}

interface UnassignPartnerBookkeeperProps {
    bookkeeper: PartnerEmployee
    company: PartnerCompany
}

const UnassignPartnerBookkeeper = ({ bookkeeper, company }: UnassignPartnerBookkeeperProps) => {
    const [showUnassignConfirm, setShowUnassignConfirm] = useState(false)
    const { loading, unassignBookkeeper } = useUnassignBookkeeper(bookkeeper, company)

    return (
        <Popconfirm
            title={
                <Text>
                    <strong>{getPartnerEmployeeName(bookkeeper)}</strong> will no longer have access
                    to <strong>{company.name}</strong>
                </Text>
            }
            open={showUnassignConfirm}
            onConfirm={unassignBookkeeper}
            onCancel={() => setShowUnassignConfirm(false)}
            okButtonProps={{
                loading,
                danger: true,
                icon: loading ? <LoadingOutlined /> : <UserDeleteOutlined />,
            }}
            okText="Unassign"
        >
            <Button
                aria-label={`Unassign ${getPartnerEmployeeName(bookkeeper)}`}
                type="link"
                danger
                onClick={() => setShowUnassignConfirm(true)}
            >
                Unassign
            </Button>
        </Popconfirm>
    )
}

interface PartnerClientBookkeepersProps {
    bookkeepers: PartnerEmployee[]
    company: PartnerCompany
}

const PartnerClientBookkeepers = ({ bookkeepers, company }: PartnerClientBookkeepersProps) => {
    const navigate = useNavigate()
    const onBookkeeperClick = ({ userId }: PartnerEmployee) =>
        navigate(`/customer-success/users/${userId}`)

    const columns: ColumnProps<PartnerEmployee>[] = [
        {
            title: 'User ID',
            dataIndex: 'userId',
            key: 'userId',
            render: (id) => <Text copyable>{id}</Text>,
        },
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (_, bookkeeper) => (
                <ActionsContainer>
                    <Button type="link" onClick={() => onBookkeeperClick(bookkeeper)}>
                        {getPartnerEmployeeName(bookkeeper)}
                    </Button>
                </ActionsContainer>
            ),
            sorter: (a: PartnerEmployee, b: PartnerEmployee) =>
                getPartnerEmployeeName(a).localeCompare(getPartnerEmployeeName(b)),
            defaultSortOrder: 'ascend' as SortOrder,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (email: string) => (
                <Text copyable={{ text: email }}>
                    <BreakText>{email}</BreakText>
                </Text>
            ),
        },
        {
            title: 'Unassign',
            dataIndex: 'unassign',
            key: 'unassign',
            render: (_, bookkeeper) => (
                <UnassignPartnerBookkeeper bookkeeper={bookkeeper} company={company} />
            ),
        },
    ]

    return <Table loading={!bookkeepers} rowKey="id" dataSource={bookkeepers} columns={columns} />
}

export const ConnectCompany = ({
    close,
    partner,
    partnerClients = [],
    visible,
}: {
    close: () => void
    partner: Partner
    visible: boolean
    partnerClients?: PartnerCompany[]
}) => {
    const [companyIdSearch, setCompanyIdSearch] = useState('')
    const [level, setLevel] = useState<PartnerAccessLevel>(PartnerAccessLevel.EXTENDED)
    const { data: deimosCompany } = useGetCompany(companyIdSearch)
    const alreadyConnected = partnerClients.map((c) => c.id).includes(deimosCompany?.id || '')
    const { refetch: refetchClients } = bff.partnerClients.getPartnerClients.useQuery({
        partnerId: partner.id,
    })

    const onCompanySearch = (companyId: string) => {
        setCompanyIdSearch(companyId)
    }

    const onConnectPartnerClient = async () => {
        if (!deimosCompany?.id) {
            message.warning('No company ID was provided')
            return
        }
        if (partner.ownCompanyId === deimosCompany.id) {
            message.warning('Cannot connect the partner to their own company')
            return
        }

        const { success } = await connectPartnerClient(partner.id, deimosCompany.id, level)
        if (success) {
            refetchClients()
            message.success(`${deimosCompany.name} successfully connected with ${level} access`)
        } else {
            message.error(
                <Text>
                    Unable to connect company. Please try again or contact{' '}
                    <Link href="https://getpleo.slack.com/archives/CHHLJM04D" target="_blank">
                        #domain-partnerships-team-partner-experience
                    </Link>
                </Text>,
                10
            )
        }
        return close()
    }

    return (
        <Modal
            title="Connect company"
            open={visible}
            onCancel={close}
            footer={[
                <Button
                    key="connect"
                    onClick={onConnectPartnerClient}
                    disabled={!deimosCompany?.id || alreadyConnected}
                >
                    Connect
                </Button>,
            ]}
            afterClose={() => setCompanyIdSearch('')}
        >
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                <Alert
                    message="Warning"
                    description={`This will give ${partner.name} bookkeeper access to the company.`}
                    type="warning"
                    showIcon
                />
                <Search
                    placeholder="Company ID"
                    allowClear
                    onSearch={onCompanySearch}
                    style={{ width: 400 }}
                    enterButton
                />
                {deimosCompany?.id && (
                    <>
                        <Col>
                            <Text strong>{deimosCompany.name}</Text>
                            <CompanyAddress address={deimosCompany?.address} />
                        </Col>
                        <Space align="baseline" wrap>
                            <Text>
                                Access level{' - '}
                                <Link
                                    href="https://www.notion.so/pleo/Partner-Portal-Glossary-3ca5590ce55244c6ad63f386fca7659b#98d20d11658e4e1c833459e497b70cb3"
                                    target="_blank"
                                >
                                    learn more
                                </Link>
                            </Text>
                        </Space>
                        <Select
                            defaultValue={PartnerAccessLevel.EXTENDED}
                            onChange={(value) => setLevel(value)}
                        >
                            <Option value={PartnerAccessLevel.EXTENDED}>Extended</Option>
                            <Option value={PartnerAccessLevel.BASIC}>Basic</Option>
                        </Select>
                    </>
                )}
                {alreadyConnected && (
                    <Alert
                        message={`This company is already connect to ${partner.name}`}
                        type="warning"
                        showIcon
                    />
                )}
            </Space>
        </Modal>
    )
}
interface BookkeepersCellProps {
    company: PartnerCompany
    partnerId: string
}
const BookkeepersCell = ({ company, partnerId }: BookkeepersCellProps) => {
    const [showBookkeepersModal, setShowBookkeepers] = useState(false)
    const { data: employees } = useGetPartnerEmployees(partnerId)
    const { bookkeepers, unassignedBookkeepers } = getAssignedAndUnassignedBookkeepers(
        employees || [],
        company
    )

    const getBookkeeperButtonTitle = () => {
        switch (bookkeepers.length) {
            case 0:
                return 'None'
            case 1:
                return getPartnerEmployeeName(bookkeepers[0])
            default:
                return `${bookkeepers.length} bookkeepers`
        }
    }

    return (
        <ActionsContainer>
            <Button type="link" onClick={() => setShowBookkeepers(true)}>
                {getBookkeeperButtonTitle()}
            </Button>

            <Modal
                title={
                    <Row justify="space-between">
                        <Col span={16}>
                            <Text ellipsis>Bookkeepers for {company.name}</Text>
                        </Col>
                        <Col pull={1}>
                            <AssignPartnerBookkeeper
                                bookkeepers={unassignedBookkeepers}
                                company={company}
                                partnerId={partnerId}
                            />
                        </Col>
                    </Row>
                }
                open={showBookkeepersModal}
                onCancel={() => setShowBookkeepers(false)}
                footer={null}
                width={1200}
                centered
                destroyOnClose
            >
                <PartnerClientBookkeepers bookkeepers={bookkeepers} company={company} />
            </Modal>
        </ActionsContainer>
    )
}

interface PartnerClientsProps {
    canDisconnect: boolean
    companies: PartnerCompany[]
    fetchingClients: boolean
    onConfirmDisconnect: (company: PartnerCompany) => void
    partnerId: string
}

export const PartnerClients = ({
    canDisconnect,
    companies,
    fetchingClients,
    onConfirmDisconnect,
    partnerId,
}: PartnerClientsProps) => {
    const navigate = useNavigate()
    const [disconnectClientsVisible, setDisconnectClientsVisible] = useState(false)

    const goToCompany = (companyId: string) => navigate(`/customer-success/companies/${companyId}`)

    const companyColumns: ColumnProps<PartnerCompany>[] = [
        {
            title: 'Company ID',
            dataIndex: 'id',
            render: (id) => <Text copyable>{id}</Text>,
            width: '27%',
        },
        {
            title: 'Company name',
            dataIndex: 'name',
            render: (_, company) => (
                <Link onClick={() => goToCompany(company.id)}>{company.name}</Link>
            ),
            width: '28%',
        },
        {
            title: 'FDD',
            dataIndex: 'fdd',
            render: (fdd) => (fdd ? dayjs(fdd).format('D MMM, YYYY') : 'No'),
            width: '15%',
        },
        {
            title: 'Access level',
            dataIndex: 'accessLevel',
            width: '10%',
        },
        {
            title: 'Bookkeepers',
            dataIndex: 'bookkeeperUserIds',
            render: (_, company) => <BookkeepersCell company={company} partnerId={partnerId} />,
            width: '20%',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            render: (_, company) => (
                <ActionsContainer>
                    <Button
                        type="link"
                        danger
                        data-testid="disconnect"
                        disabled={!canDisconnect}
                        onClick={() => onConfirmDisconnect(company)}
                    >
                        Disconnect
                    </Button>
                </ActionsContainer>
            ),
        },
    ]
    return (
        <ContentContainer>
            <Table
                loading={fetchingClients}
                rowKey="id"
                dataSource={companies}
                columns={companyColumns}
            />
            <Space>
                <Button onClick={() => setDisconnectClientsVisible(true)}>
                    Disconnected clients
                </Button>
            </Space>
            <Modal
                title="Disconnected clients"
                open={disconnectClientsVisible}
                onCancel={() => setDisconnectClientsVisible(false)}
                footer={null}
                width={1000}
                centered
                destroyOnClose
            >
                <DisconnectedPartnerClients partnerId={partnerId} />
            </Modal>
        </ContentContainer>
    )
}

export const PartnerClientsContainer = ({ partner }: { partner: Partner }) => {
    const [query, setQuery] = useState('')
    const [showConnectCompany, setShowConnectCompany] = useState(false)
    const canManagePartners = useHasPermissions(['partner-manager']) // only enforced in frontend
    const canEditPartnerCompany = useHasPermissions(['company-role', 'styx']) // only 'company-role' enforced in backend
    const canDisconnectCompany = canManagePartners || canEditPartnerCompany
    const {
        data: companies,
        error: companiesError,
        isLoading,
        refetch,
    } = bff.partnerClients.getPartnerClients.useQuery({ partnerId: partner.id })
    const handleFilter = (event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)
    const filterdCompanies = () =>
        companies?.filter((c) => c.name.toLowerCase().includes(query.toLowerCase())) || []

    const onConfirmDisconnect = (company: PartnerCompany) => {
        confirm({
            icon: <ExclamationCircleOutlined />,
            title: 'Are you sure?',
            content: `This action will disconnect client ${company.name}`,
            okText: 'Disconnect',
            okType: 'danger',
            async onOk() {
                const {
                    error,
                    message: msg,
                    success,
                } = await disconnectPartnerClient(partner.id, company.id)
                if (success) {
                    refetch()
                    message.success(`Done! ${company.name} has been disconnected`)
                } else if (error && msg) {
                    message.error(`Error: ${msg}`, 8)
                } else {
                    message.error('Something is not working. Please contact Team Zeus', 8)
                }
            },
            width: 500,
        })
    }

    if (isLoading) {
        return (
            <>
                <Skeleton.Input active />
                <Divider />
                <Skeleton.Button active block />
                <Divider />
                <Skeleton.Button active block />
                <Divider />
                <Skeleton.Button active block />
            </>
        )
    }

    if (companiesError) {
        return (
            <Result
                status="500"
                title="Something went wrong"
                subTitle={`We had trouble fetching clients for ${partner.name}`}
            />
        )
    }

    if (companies?.length === 0) {
        return (
            <>
                <Result
                    title={`${partner.name} does not have any clients`}
                    extra={
                        <Button
                            type="primary"
                            key="connect-company"
                            onClick={() => setShowConnectCompany(true)}
                        >
                            Connect Company
                        </Button>
                    }
                />
                <ConnectCompany
                    close={() => setShowConnectCompany(false)}
                    partner={partner}
                    visible={showConnectCompany}
                />
            </>
        )
    }

    return (
        <ContentContainer>
            <Row justify="space-between">
                <Input
                    placeholder="Filter by company name"
                    onChange={handleFilter}
                    style={{ width: inputWidth.medium }}
                    data-testid="filter-companies"
                />
                <Button onClick={() => setShowConnectCompany(true)}>Connect company</Button>
                <ConnectCompany
                    close={() => setShowConnectCompany(false)}
                    partner={partner}
                    visible={showConnectCompany}
                    partnerClients={companies}
                />
            </Row>
            {!!companies && (
                <PartnerClients
                    canDisconnect={canDisconnectCompany}
                    companies={filterdCompanies()}
                    fetchingClients={false}
                    onConfirmDisconnect={onConfirmDisconnect}
                    partnerId={partner.id}
                />
            )}
        </ContentContainer>
    )
}

export default PartnerClientsContainer
