import { Avatar, Breadcrumb, Button, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { HistoryOutlined, UserAddOutlined } from '@ant-design/icons'

import { bff } from '../bff-hooks'
import { useOddDetailContext } from '../context'

import type { AssignedAnalyst } from 'bff/moons/generated/case-manager'
import { StatusTag } from './status-tag'

const { Text, Title, Paragraph } = Typography

export const Header = () => {
    const { caseId, historyModal } = useOddDetailContext()
    const { data } = bff.pageHeader.getOdd.useQuery({ caseId })

    return (
        <Space direction="vertical">
            <Breadcrumbs companyName={data?.companyName} />
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Space direction="vertical" size={0}>
                    <Title level={3} style={{ margin: 0 }}>
                        {data?.companyName}
                    </Title>
                    <Paragraph type="secondary">{data?.address}</Paragraph>
                    <Space>
                        {data?.status && <StatusTag status={data.status} />}
                        <User assignee={data?.assignee} />
                    </Space>
                </Space>
                <Space>
                    <Button icon={<HistoryOutlined />} onClick={historyModal.open}>
                        History
                    </Button>
                </Space>
            </Space>
        </Space>
    )
}

const Breadcrumbs = ({ companyName }: { companyName?: string }) => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to={'/compliance'}>Compliance</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={'/compliance/odd'}>Ongoing Due Diligence</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{companyName}</Breadcrumb.Item>
        </Breadcrumb>
    )
}

const User = ({ assignee }: { assignee?: AssignedAnalyst }) => {
    const { caseId } = useOddDetailContext()
    const { mutateAsync: assignCaseToMe, isLoading } = bff.pageHeader.assignCaseToMe.useMutation()

    const caseHandlerInitials = assignee
        ? `${assignee.firstName} ${assignee.lastName}`
              .split(' ')
              .map((name) => name[0])
              .join('')
              .toUpperCase()
        : ''

    const handleAssignToMe = async () => {
        await assignCaseToMe({ caseId })
    }

    return assignee ? (
        <Space>
            <Avatar shape="square" size="small">
                {caseHandlerInitials}
            </Avatar>
            <Text>
                {assignee?.firstName} {assignee?.lastName}
            </Text>
        </Space>
    ) : (
        <Space>
            <Avatar shape="square" size="small" icon={<UserAddOutlined />} />
            <Button
                type="link"
                onClick={handleAssignToMe}
                loading={isLoading}
                style={{ padding: '4px 0' }}
            >
                Assign to me
            </Button>
        </Space>
    )
}
