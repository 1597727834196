import { useParams } from 'react-router-dom'
import { creditBff } from '../bff'
import { Alert, Button, notification, Skeleton, Table } from 'antd'
import type { ColumnsType } from 'antd/lib/table/interface'
import type { StatementDto } from 'bff/moons/generated/kalyke'
import formatCurrency from 'utils/format-currency'
import { ExportOutlined } from '@ant-design/icons'
import { convertToMajorCurrency } from 'utils/minor-currency'
import { formatDate } from 'packages/dates/dates'

export default function CreditBilling() {
    const { id } = useParams()
    const { data, isLoading, isError } = creditBff.getBilling.useQuery({ companyId: id })
    const { mutateAsync: getStatementUrl } =
        creditBff.getOverdraftStatementDocumentUrl.useMutation()

    if (isLoading) {
        return <Skeleton active />
    }

    if (isError) {
        return <Alert message="There was an error fetching the statements" type="error" />
    }

    const handleDocumentDownload = (statementId: string) => async () => {
        try {
            const s3Url = await getStatementUrl({ statementId })
            if (!s3Url) {
                throw new Error('Failed to download document')
            }

            const response = await fetch(s3Url)
            if (!response.ok) {
                throw new Error('Failed to download document')
            }
            const blob = await response.blob()
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (err) {
            notification.error({
                message: 'Document download failed!',
            })
        }
    }

    const columns: ColumnsType<StatementDto> = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            render: (date) => formatDate(date),
        },
        {
            title: 'Principal amount',
            dataIndex: 'principalAmount',
            key: 'principalAmount',
            render: (_, { principalAmount }) =>
                formatCurrency(
                    convertToMajorCurrency(principalAmount.value),
                    principalAmount.currency
                ),
        },
        {
            title: 'Interest accrued',
            dataIndex: 'interestAccrued',
            key: 'interestAccrued',
            render: (_, { interestAccrued }) =>
                formatCurrency(
                    convertToMajorCurrency(interestAccrued.value),
                    interestAccrued.currency
                ),
        },
        {
            title: 'Statement',
            dataIndex: 'id',
            key: 'id',
            render: (_, { id: statementId }) => (
                <Button
                    type="link"
                    onClick={handleDocumentDownload(statementId)}
                    icon={<ExportOutlined />}
                >
                    View
                </Button>
            ),
        },
    ]

    return <Table columns={columns} dataSource={data.statements} />
}
