import { absurd } from 'utils/absurd'
import type { App, PersistedApp } from './app'
import type { CreateAppFormValues } from './create-app-form'
import { joinCommaSeparated, splitCommaSeparated } from '../util/conversions'

export type EditAppFormValues = CreateAppFormValues & {
    id: string
    status: App['status']
    description?: string
    developerName?: string
    initiationUrl?: string
    targetSystemUrl?: string
    settingsUrl?: string
    logoUrl?: string
    activationCallback?: boolean
    tags?: string
    metadata?: any
    supportedCountries?: string
    enabledByFeatures?: string
}

export const toApp = (formValues: EditAppFormValues): PersistedApp => ({
    id: formValues.id,
    authenticationMethod: formValues.authenticationMethod,
    name: formValues.name,
    slug: formValues.slug,
    type: formValues.type,
    category: formValues.category,
    description: formValues.description,
    developerName: formValues.developerName,
    initiationUrl: formValues.initiationUrl,
    targetSystemUrl: formValues.targetSystemUrl,
    settingsUrl: formValues.settingsUrl,
    logoUrl: formValues.logoUrl,
    activationCallback: formValues.activationCallback,
    tags: splitCommaSeparated(formValues.tags),
    metadata: formValues.metadata,
    supportedCountries: splitCommaSeparated(formValues.supportedCountries),
    enabledByFeatures: splitCommaSeparated(formValues.enabledByFeatures),
    status: formValues.status,
})

export const fromApp = (app: PersistedApp): EditAppFormValues => {
    switch (app.authenticationMethod) {
        case 'NONE':
            return { ...toBaseEditAppFormValues(app), authenticationMethod: 'NONE' }

        case 'API_KEY':
            return {
                ...toBaseEditAppFormValues(app),
                authenticationMethod: 'API_KEY',
                // FIXME This field should come from the APIKeyClient
                scope: toScopeFormField(''),
            }

        case 'OAUTH2':
            return {
                ...toBaseEditAppFormValues(app),
                authenticationMethod: 'OAUTH2',
                // FIXME These fields should come from the OAuth2Client
                scope: toScopeFormField(''),
                redirectUris: toRedirectUrisFormField([]),
            }

        default:
            return absurd(app.authenticationMethod)
    }
}

const toBaseEditAppFormValues = (
    app: PersistedApp
): Omit<EditAppFormValues, 'authenticationMethod'> => ({
    id: app.id,
    name: app.name,
    slug: app.slug,
    type: app.type,
    category: app.category,
    description: app.description,
    developerName: app.developerName,
    initiationUrl: app.initiationUrl?.toString() ?? '',
    targetSystemUrl: app.targetSystemUrl?.toString() ?? '',
    settingsUrl: app.settingsUrl?.toString() ?? '',
    logoUrl: app.logoUrl?.toString() ?? '',
    activationCallback: app.activationCallback,
    tags: joinCommaSeparated(app.tags),
    metadata: app.metadata,
    supportedCountries: joinCommaSeparated(app.supportedCountries),
    enabledByFeatures: joinCommaSeparated(app.enabledByFeatures),
    status: app.status ?? 'UNPUBLISHED',
})

const toScopeFormField = (scope: string): string[] => scope.split(' ')
const toRedirectUrisFormField = (redirectUris: string[]): string => redirectUris.join('\n')
