import { datadogRum } from '@datadog/browser-rum'
import React, { useEffect, createContext } from 'react'

import config from 'config'
import { useUser } from './user-context'
import { useIsLoggedIn } from './auth-context'

// Initializes Datadog's static interface.
if (config.datadog.env === 'production') {
    datadogRum.init({
        env: config.datadog.env,
        service: 'browser-backoffice',
        allowedTracingOrigins: [/^https:\/\/api?.*\.pleo\.io?/, /^https:\/\/auth?.*\.pleo\.io?/],
        site: 'datadoghq.eu',
        sampleRate: 100,
        // Never enable trackInteractions! It can leak sensitive data.
        trackInteractions: false,
        // Datadog tokens meant for browser-side usage. Not sensitive.
        applicationId: '019cda9e-df22-4ba1-90d6-7b3c01761679',
        clientToken: 'pub3d107c1d73f3fd136a23ce1dab2fdc30',
        defaultPrivacyLevel: 'mask',
    })
}

export const UserContext = createContext<{ userId: string | undefined }>({ userId: undefined })

function setUser(id: string | undefined, email: string | undefined) {
    datadogRum.onReady(() => {
        datadogRum.setUser({
            id,
            email,
        })
    })
}

function removeUser() {
    datadogRum.onReady(() => {
        datadogRum.removeUser()
    })
}

export const DatadogRUMProvider: React.FC<
    React.PropsWithChildren<{
        children: React.ReactChild
    }>
> = ({ children }) => {
    const user = useUser()
    const isLoggedIn = useIsLoggedIn()
    const datadogContextValue = { userId: user.id || undefined, userEmail: user.email || undefined }

    useEffect(() => {
        if (isLoggedIn) {
            datadogRum.startSessionReplayRecording()
        } else {
            datadogRum.stopSessionReplayRecording()
        }
    }, [isLoggedIn])

    useEffect(() => {
        setUser(datadogContextValue.userId, datadogContextValue.userEmail)
        return removeUser
    }, [datadogContextValue.userId, datadogContextValue.userEmail])

    return <UserContext.Provider value={datadogContextValue}>{children}</UserContext.Provider>
}
