import React, { FC, ReactNode, useState } from 'react'
import {
    Button,
    Card,
    Descriptions,
    Modal,
    Result,
    Space,
    Tooltip,
    Typography,
    message,
    Collapse,
} from 'antd'
import styled from 'styled-components'

import { getBalanceStatement, useCompanyBalance } from 'services/deimos/companies'
import {
    useHasPermissions,
    withPermissionGuard,
} from 'components/permission-guard/permission-guard'
import { useIsOverdraftEnabled, useOverdraftEligibility } from 'services/deimos/overdraft'

import { bff as complianceBff } from 'pages/compliance/bff/bff'
import type { SubWalletWithBalance } from 'pages/compliance/bff/sub-wallet.helpers.bff'

import type { Amount } from '@pleo-io/deimos'
import { AutoTopUpStatus } from 'types/company-top-up-settings'
import type { Balance } from 'types/company-balance'
import { BankAccount } from 'pages/customer-success/company/bank-account-details'
import ConfirmAmountForm from 'components/company-balance/confirm-amount/confirm-amount-form'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import LinkForm from 'components/company-balance/company-link/company-link-form'
import RequestBalanceAdjustmenttForm from 'components/balance-adjustment/request-balance-adjustment-form'
import RequestBalanceStatementForm from 'components/balance-statement/request-balance-statement.tsx/request-balance-statement-form'
import { adjustBalance } from 'services/prospero/companies'
import dayjs from 'packages/dayjs'
import { linkTransaction } from 'services/deimos/bank-transactions'
import { useDirectDebitEligibility, EligibilityStatus } from 'services/cupid/direct-debit'
import { usePleoReserveEligibility } from 'services/deimos/pleo-reserve'
import { useWalletAutoTopUp } from 'services/deimos/wallet'
import { uploadUBOFileForCompany } from 'services/telesto/banking-circle'
import { spacing } from 'theme/tokens'
import { bff } from 'components/bff-hooks'

const { Text } = Typography
const { confirm } = Modal

interface CreditLimit {
    eligible: Balance | undefined
    active: Balance | undefined
}

export interface CompanyBalanceProps {
    onCredit: () => void
    onDebit: () => void
    onLink: () => void
    onEmpty: () => void
    onStatement: () => void
    onDisableAutoTopUp: () => void
    onBalanceAdjustment: () => void
    onViewBankAccountDetails: () => void
    onUploadUBOFile: () => void
    current?: Balance
    available?: Balance
    subWallets?: SubWalletWithBalance[]
    reserveLimit: CreditLimit
    overdraftLimit: CreditLimit
    directDebitEligibility: EligibilityStatus
    isCrediting: boolean
    isDebiting: boolean
    isEmptying: boolean
    isDisablingAutoTopUp: boolean
    isWalletAutoTopEnabled: boolean
    isLinkingBankTransaction: boolean
    isDownloadingStatement: boolean
    walletId?: string
    hasBalanceStatementPermissions: boolean
    hasAutoTopUpChangePermissions: boolean
    hasBalanceAdjustmentPermissions: boolean
    isAdjustingBalance: boolean
    isBankAccountDetailsVisible: boolean
    hasBalanceAmendmentPermissions: boolean
    hasUploadUBOPermission: boolean
    isUploadingUBOFile: boolean
}

const EligibleItem = ({ status }: { status: EligibilityStatus }) => {
    return <Text>{status}</Text>
}

const BalanceItem = ({
    balance,
    minimumFractionDigits,
}: {
    balance: Omit<Balance, 'updated'> | null | undefined
    minimumFractionDigits?: number
}) => {
    if (!balance) {
        return <>-</>
    }
    return (
        <Text>
            {balance.currency}{' '}
            {balance.value.toLocaleString(navigator.language, {
                minimumFractionDigits: minimumFractionDigits ?? 0,
            })}
        </Text>
    )
}

const Panel = styled(Collapse.Panel)`
    margin-top: ${spacing.space8};
    margin-bottom: ${spacing.space8};

    & .ant-collapse-header {
        padding: 0 !important;
    }
`

const SubWalletCollapsibleList = ({ subWallets }: { subWallets: SubWalletWithBalance[] }) => (
    <Collapse ghost>
        <Panel key="active-sub-wallets" header={`Active sub-wallets (${subWallets.length})`}>
            {subWallets.length ? (
                <Space direction="vertical">
                    <ul style={{ paddingLeft: spacing.space8, margin: 0 }}>
                        {subWallets.map((subWallet) => (
                            <li key={subWallet.id}>
                                <Text>
                                    {subWallet.name}:&nbsp;
                                    <b>
                                        <BalanceItem
                                            balance={subWallet.balance}
                                            minimumFractionDigits={2}
                                        />
                                    </b>
                                </Text>
                            </li>
                        ))}
                    </ul>
                </Space>
            ) : (
                <Text>None</Text>
            )}
        </Panel>
    </Collapse>
)

export const CompanyBalance: FC<React.PropsWithChildren<CompanyBalanceProps>> = ({
    onCredit,
    onDebit,
    onEmpty,
    onLink,
    onStatement,
    onDisableAutoTopUp,
    onViewBankAccountDetails,
    current,
    available,
    subWallets,
    reserveLimit,
    overdraftLimit,
    directDebitEligibility,
    isCrediting,
    isDebiting,
    isEmptying,
    isLinkingBankTransaction,
    isDownloadingStatement,
    isDisablingAutoTopUp,
    isWalletAutoTopEnabled,
    isBankAccountDetailsVisible,
    walletId,
    hasBalanceStatementPermissions,
    hasAutoTopUpChangePermissions,
    hasBalanceAdjustmentPermissions,
    onBalanceAdjustment,
    isAdjustingBalance,
    hasBalanceAmendmentPermissions,
    onUploadUBOFile,
    isUploadingUBOFile,
    hasUploadUBOPermission,
}) => {
    const BalanceAmendmentToolTip = ({ children }: { children: ReactNode }) => {
        return (
            <Tooltip
                placement="topRight"
                title={
                    !hasBalanceAmendmentPermissions && (
                        <Typography.Text style={{ color: 'white' }}>
                            You don't have permission to perform this action. Permission required:{' '}
                            <Typography.Text code style={{ color: 'white', whiteSpace: 'nowrap' }}>
                                prospero
                            </Typography.Text>
                        </Typography.Text>
                    )
                }
            >
                {children}
            </Tooltip>
        )
    }

    return (
        <Space direction="vertical" data-testid="balance-details">
            <Descriptions column={{ lg: 2, md: 1, sm: 1 }} size="small">
                <Descriptions.Item label="Wallet ID" span={2}>
                    <Text copyable>{walletId ? walletId : 'None found'}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Current balance">
                    <BalanceItem balance={current} />
                </Descriptions.Item>
                <Descriptions.Item label="Available balance">
                    <BalanceItem balance={available} />
                </Descriptions.Item>
                {subWallets && (
                    <Descriptions.Item span={2}>
                        <SubWalletCollapsibleList subWallets={subWallets} />
                    </Descriptions.Item>
                )}
                <Descriptions.Item label="Updated at">
                    {current?.updated ? dayjs(current.updated).format('lll') : ''}
                </Descriptions.Item>
                <Descriptions.Item label="Updated at">
                    {available?.updated ? dayjs(available.updated).format('lll') : ''}
                </Descriptions.Item>
            </Descriptions>
            <Descriptions column={{ lg: 2, md: 1, sm: 1 }} size="small">
                <Descriptions.Item label="Reserve limit">
                    <Space direction="vertical">
                        <Text>
                            {'Eligible: '}
                            {reserveLimit.eligible ? (
                                <BalanceItem balance={reserveLimit.eligible} />
                            ) : (
                                'Not eligible'
                            )}
                        </Text>
                        <Text>
                            {'Active: '}
                            <BalanceItem balance={reserveLimit.active} />
                            {reserveLimit.active === undefined && ' (not activated)'}
                        </Text>
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label="Overdraft limit">
                    <Space direction="vertical">
                        <Text>
                            {'Eligible: '}
                            {overdraftLimit.eligible ? (
                                <BalanceItem balance={overdraftLimit.eligible} />
                            ) : (
                                'Not eligible'
                            )}
                        </Text>
                        <Text>
                            {'Active: '}
                            <BalanceItem balance={overdraftLimit.active} />
                            {overdraftLimit.active === undefined && ' (not activated)'}
                        </Text>
                    </Space>
                </Descriptions.Item>
            </Descriptions>
            <Descriptions column={{ lg: 1, md: 1, sm: 1 }} size="small">
                <Descriptions.Item label="Direct Debit">
                    <Space direction="vertical">
                        <Text>
                            {'Eligible: '}
                            <EligibleItem status={directDebitEligibility} />
                        </Text>
                    </Space>
                </Descriptions.Item>
            </Descriptions>
            <Space wrap>
                <BalanceAmendmentToolTip>
                    <Button
                        data-testid="creditButton"
                        onClick={onCredit}
                        disabled={!hasBalanceAmendmentPermissions || isCrediting}
                        loading={isCrediting}
                    >
                        Credit
                    </Button>
                </BalanceAmendmentToolTip>
                <BalanceAmendmentToolTip>
                    <Button
                        data-testid="debitButton"
                        onClick={onDebit}
                        disabled={!hasBalanceAmendmentPermissions || isDebiting}
                        loading={isDebiting}
                    >
                        Debit
                    </Button>
                </BalanceAmendmentToolTip>
                <BalanceAmendmentToolTip>
                    <Button
                        data-testid="emptyButton"
                        onClick={onEmpty}
                        disabled={!hasBalanceAmendmentPermissions || isEmptying}
                        loading={isEmptying}
                    >
                        Empty
                    </Button>
                </BalanceAmendmentToolTip>

                <Button
                    data-testid="manualLinkButton"
                    onClick={onLink}
                    disabled={isLinkingBankTransaction}
                    loading={isLinkingBankTransaction}
                >
                    Manually link bank transaction
                </Button>
                <Button
                    data-testid="viewBankAccountDetails"
                    onClick={onViewBankAccountDetails}
                    disabled={isBankAccountDetailsVisible}
                >
                    View Bank Account details
                </Button>

                <Button
                    data-testid="uploadUBOFileButton"
                    onClick={onUploadUBOFile}
                    disabled={!hasUploadUBOPermission || isUploadingUBOFile}
                    loading={isUploadingUBOFile}
                >
                    Upload UBO file
                </Button>

                {hasBalanceStatementPermissions && (
                    <Button
                        data-testid="downloadStatementButton"
                        onClick={onStatement}
                        disabled={isDownloadingStatement}
                        loading={isDownloadingStatement}
                    >
                        Download balance statement
                    </Button>
                )}

                {hasAutoTopUpChangePermissions && (
                    <Tooltip
                        placement="topRight"
                        title={
                            !isWalletAutoTopEnabled && 'Auto top-up is not enabled for this company'
                        }
                    >
                        <Button
                            data-testid="disableAutoTopUpButton"
                            onClick={onDisableAutoTopUp}
                            disabled={!isWalletAutoTopEnabled || isDisablingAutoTopUp}
                            loading={isDisablingAutoTopUp}
                        >
                            Disable Auto top up
                        </Button>
                    </Tooltip>
                )}

                {hasBalanceAdjustmentPermissions && (
                    <Button
                        data-testid="performBalanceAdjustmentButton"
                        onClick={onBalanceAdjustment}
                        disabled={!hasBalanceAdjustmentPermissions}
                        loading={isAdjustingBalance}
                    >
                        Balance adjustment (internal and external)
                    </Button>
                )}
            </Space>
        </Space>
    )
}

const useActiveCreditLimit = (companyId: string) => {
    const { data: companyBalance } = useCompanyBalance(companyId)

    return companyBalance?.balance.creditLimit
}

const useReserveLimit = (companyId: string): CreditLimit => {
    const { data: pleoReserve } = usePleoReserveEligibility(companyId)
    const activeCreditLimit = useActiveCreditLimit(companyId)

    let eligible: Balance | undefined
    if (pleoReserve?.eligibility === 'ELIGIBLE' && pleoReserve?.terms) {
        eligible = {
            value: pleoReserve.terms.reserveLimit,
            currency: pleoReserve.terms.currency,
            updated: dayjs().format(),
        }
    }

    let active: Balance | undefined
    if (pleoReserve?.enabled) {
        active = activeCreditLimit!
    }
    return {
        eligible,
        active,
    }
}

const useOverdraftLimit = (companyId: string): CreditLimit => {
    const { data: overdraftEligibility } = useOverdraftEligibility(companyId)
    const { isOverdraftEnabled } = useIsOverdraftEnabled(companyId)
    const activeCreditLimit = useActiveCreditLimit(companyId)

    let eligible: Balance | undefined
    if (overdraftEligibility?.eligibility.eligible && overdraftEligibility?.terms) {
        eligible = {
            value: overdraftEligibility.terms.limit,
            currency: overdraftEligibility.terms.currency,
            updated: dayjs().format(),
        }
    }

    let active: Balance | undefined
    if (isOverdraftEnabled) {
        active = activeCreditLimit!
    }

    return {
        active,
        eligible,
    }
}

const CompanyBalanceContainer: FC<React.PropsWithChildren<{ companyId: string }>> = ({
    companyId,
}) => {
    const [creditVisible, setCreditVisible] = useState(false)
    const [debitVisible, setDebitVisible] = useState(false)
    const [linkVisible, setLinkVisible] = useState(false)
    const [statementVisible, setStatementVisible] = useState(false)
    const [adjustmentVisible, setAdjustmentVisible] = useState(false)
    const [bankAccountDetailsVisible, setBankAccountDetailsVisible] = useState(false)
    const [isCrediting, setIsCrediting] = useState(false)
    const [isDebiting, setIsDebiting] = useState(false)
    const [isEmptying, setIsEmptying] = useState(false)
    const [isDisablingAutoTopUp, setIsDisablingAutoTopUp] = useState(false)
    const [isLinkingBankTransaction, setIsLinkingBankTransaction] = useState(false)
    const [isDownloadingStatement, setIsDownloadingStatement] = useState(false)
    const [isAdjustingBalance, setIsAdjustingBalance] = useState(false)
    const [balanceMismatch, setBalanceMismatch] = useState(false)
    const [isUploadingUBOFile, setIsUploadingUBOFile] = useState(false)

    const hasBalanceStatementPermissions = useHasPermissions(['download-balance-statement'])
    const hasBalanceAdjustmentPermissions = useHasPermissions(['prospero:balance-adjustments'])
    const hasBalanceAmendmentPermissions = useHasPermissions(['prospero'])
    const isUserPayOpsTeamMember = useHasPermissions(['payops'])
    const isUserMercuryTeamMember = useHasPermissions(['mercury'])
    const hasAutoTopUpChangePermissions = isUserPayOpsTeamMember || isUserMercuryTeamMember
    const hasUploadUBOPermission = isUserPayOpsTeamMember

    // Todo: eventually move all the API calls from this component inside the BFF query procedure
    const { data: companyBalanceInfo } =
        complianceBff.companyBalance.getCompanyBalanceInfo.useQuery({ companyId })

    const { data: walletId } = bff.components.companyBalance.getPrimaryWalletId.useQuery({
        companyId,
    })

    const { data, error: balanceError, mutations } = useCompanyBalance(companyId)
    const available = data?.balance.available
    const current = data?.balance.current
    const reserveLimit = useReserveLimit(companyId)
    const overdraftLimit = useOverdraftLimit(companyId)
    const eligibility = useDirectDebitEligibility(companyId)

    const { data: walletAutoTopUpSettings, mutations: walletAutoTopUpMutations } =
        useWalletAutoTopUp(companyId)
    const { autoTopupStatus } = walletAutoTopUpSettings ?? {}

    const onCredit = async (amount: Amount, note: string) => {
        try {
            setIsCrediting(true)
            await mutations.creditBalance(amount, note)
            message.success('Successfully credited balance.')
        } catch (e) {
            message.error((e as Error).message)
        } finally {
            setIsCrediting(false)
        }
    }

    const onDebit = async (amount: Amount, note: string) => {
        try {
            setIsDebiting(true)
            await mutations.debitBalance(amount, note)
            message.success('Successfully debited balance.')
        } catch (e) {
            message.error((e as Error).message)
        } finally {
            setIsDebiting(false)
        }
    }

    const onLink = async (bankTransactionId: string) => {
        try {
            setIsLinkingBankTransaction(true)
            await linkTransaction(companyId, bankTransactionId)
            message.success('Successfully linked a bank transaction.')
        } catch (e) {
            message.error((e as Error).message)
        } finally {
            setIsLinkingBankTransaction(false)
        }
    }

    const onEmpty = async () => {
        try {
            setIsEmptying(true)
            await mutations.emptyBalance()
            message.success('Successfully emptied wallet.')
        } catch (e) {
            message.error((e as Error).message)
        } finally {
            setIsEmptying(false)
        }
    }

    const onDisableAutoTopUp = async () => {
        try {
            setIsDisablingAutoTopUp(true)
            await walletAutoTopUpMutations.disableAutoTopUp()
            message.success('Successfully disabled Auto top up.')
        } catch (e) {
            message.error((e as Error).message)
        } finally {
            setIsDisablingAutoTopUp(false)
        }
    }

    const onStatement = async (date: string) => {
        setIsDownloadingStatement(true)
        const statement = await getBalanceStatement(companyId, date, !balanceMismatch)

        if (!statement) {
            setBalanceMismatch(true)
            return
        }

        const blob = new Blob([statement], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        window.open(url)

        setStatementVisible(false)
        setIsDownloadingStatement(false)
    }

    const onBalanceAdjustment = async (amountValue: number, adjustmentNote: string) => {
        setIsAdjustingBalance(true)
        const balanceDirection = amountValue > 0 ? 'increase' : 'decrease'
        if (!data) {
            throw new Error('Expected balance data to be available')
        }
        const amountCurrency = data.currency
        confirm({
            centered: true,
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure? This will ${balanceDirection} the internal and external(enfuce) balance for this company by ${amountValue} ${amountCurrency}`,
            onOk: async () => {
                await adjustBalance(companyId, amountValue, amountCurrency, adjustmentNote)
                setAdjustingBalanceFalse()
            },
        })
    }

    const onUploadUBOFile = async () => {
        try {
            setIsUploadingUBOFile(true)
            await uploadUBOFileForCompany(companyId)
            message.success('Successfully queued upload of UBO files.')
        } catch (e) {
            message.error((e as Error).message)
        } finally {
            setIsUploadingUBOFile(false)
        }
    }

    const setAdjustingBalanceFalse = () => {
        setAdjustmentVisible(false)
        setIsAdjustingBalance(false)
    }

    return (
        <>
            <Card
                title="Company balance"
                loading={!data && !balanceError}
                style={{ marginBottom: '1rem' }}
            >
                {balanceError ? (
                    <Result status="warning" title={balanceError.message} />
                ) : (
                    <CompanyBalance
                        available={available}
                        walletId={walletId}
                        current={current}
                        subWallets={companyBalanceInfo?.subWallets}
                        reserveLimit={reserveLimit}
                        overdraftLimit={overdraftLimit}
                        directDebitEligibility={eligibility.isEligible}
                        isCrediting={isCrediting}
                        isDebiting={isDebiting}
                        isEmptying={isEmptying}
                        isLinkingBankTransaction={isLinkingBankTransaction}
                        isDownloadingStatement={isDownloadingStatement}
                        isDisablingAutoTopUp={isDisablingAutoTopUp}
                        isWalletAutoTopEnabled={autoTopupStatus === AutoTopUpStatus.ENABLED}
                        isAdjustingBalance={isAdjustingBalance}
                        isBankAccountDetailsVisible={bankAccountDetailsVisible}
                        isUploadingUBOFile={isUploadingUBOFile}
                        onCredit={() => setCreditVisible(true)}
                        onDebit={() => setDebitVisible(true)}
                        onLink={() => setLinkVisible(true)}
                        onStatement={() => setStatementVisible(true)}
                        onEmpty={() =>
                            confirm({
                                centered: true,
                                icon: <ExclamationCircleOutlined />,
                                content:
                                    'Are you sure you want to empty the balance of this company?',
                                onOk: onEmpty,
                            })
                        }
                        onDisableAutoTopUp={() => {
                            confirm({
                                centered: true,
                                icon: <ExclamationCircleOutlined />,
                                content:
                                    'Are you sure you want to disable Auto top up for this company?',
                                onOk: onDisableAutoTopUp,
                            })
                        }}
                        onBalanceAdjustment={() => setAdjustmentVisible(true)}
                        onViewBankAccountDetails={() => setBankAccountDetailsVisible(true)}
                        onUploadUBOFile={() =>
                            confirm({
                                centered: true,
                                icon: <ExclamationCircleOutlined />,
                                content:
                                    'Are you sure you want to send UBO files for this company?',
                                onOk: onUploadUBOFile,
                            })
                        }
                        hasBalanceStatementPermissions={hasBalanceStatementPermissions}
                        hasAutoTopUpChangePermissions={hasAutoTopUpChangePermissions}
                        hasBalanceAdjustmentPermissions={hasBalanceAdjustmentPermissions}
                        hasBalanceAmendmentPermissions={hasBalanceAmendmentPermissions}
                        hasUploadUBOPermission={hasUploadUBOPermission}
                    />
                )}
            </Card>
            <Modal
                title="Credit (Increase Balance)"
                open={creditVisible}
                onCancel={() => setCreditVisible(false)}
                footer={null}
                width={400}
                centered
                destroyOnClose
            >
                <ConfirmAmountForm
                    onSubmit={(values) => {
                        if (!data) {
                            throw new Error('Expected data to be available')
                        }
                        onCredit({ value: values.amount, currency: data.currency }, values.note)
                        setCreditVisible(false)
                    }}
                    buttonLabel="Credit"
                />
            </Modal>
            <Modal
                title="Debit (Decrease Balance)"
                open={debitVisible}
                onCancel={() => setDebitVisible(false)}
                footer={null}
                width={400}
                centered
                destroyOnClose
            >
                <ConfirmAmountForm
                    onSubmit={(values) => {
                        if (!data) {
                            throw new Error('Expected data to be available')
                        }
                        onDebit({ value: values.amount, currency: data.currency }, values.note)
                        setDebitVisible(false)
                    }}
                    buttonLabel="Debit"
                />
            </Modal>
            <Modal
                title="Manually link bank transaction"
                open={linkVisible}
                onCancel={() => setLinkVisible(false)}
                footer={null}
                width={400}
                centered
                destroyOnClose
            >
                <LinkForm
                    onSubmit={(values) => {
                        onLink(values.bankTransactionId)
                        setLinkVisible(false)
                    }}
                />
            </Modal>
            <Modal
                title="Request balance statement"
                open={statementVisible}
                onCancel={() => setStatementVisible(false)}
                footer={null}
                width={400}
                centered
                destroyOnClose
                data-testid="balanceStatementModal"
            >
                <RequestBalanceStatementForm onSubmit={onStatement} mismatch={balanceMismatch} />
            </Modal>
            <Modal
                title="Balance adjustment (internal and external)"
                open={adjustmentVisible}
                onCancel={() => {
                    setAdjustingBalanceFalse()
                }}
                footer={null}
                width={400}
                centered
                destroyOnClose
                data-testid="balanceAdjustmentModal"
            >
                <RequestBalanceAdjustmenttForm onSubmit={onBalanceAdjustment} />
            </Modal>
            <Modal
                title="Bank Account details"
                open={bankAccountDetailsVisible}
                onCancel={() => {
                    setBankAccountDetailsVisible(false)
                }}
                footer={null}
                width={400}
                centered
                destroyOnClose
                data-testid="bankAccountDetailsModal"
            >
                <BankAccount companyId={companyId} />
            </Modal>
        </>
    )
}

export default withPermissionGuard(CompanyBalanceContainer, ['phobos'])
