import type { PartnerCompany } from '../pages/customer-success/partner/index.bff'
import { PartnerDisplayRole, PartnerEmployee, PartnerRole } from 'types/employee'

export const getUserDisplayRole = (isAdmin: boolean) =>
    isAdmin ? PartnerDisplayRole.ADMIN : PartnerDisplayRole.MEMBER

export const getUserDisplayRoleByUserRole = (role: string) =>
    role === PartnerRole.OWNER ? PartnerDisplayRole.ADMIN : PartnerDisplayRole.MEMBER

export const getPartnerEmployeeName = (employee: PartnerEmployee) => {
    const { email, firstName = '', lastName = '' } = employee
    return !firstName && !lastName ? email : firstName + (lastName ? ` ${lastName}` : '')
}

export const filterPartnerEmployeesByNameOrEmail = (employees: PartnerEmployee[], filter: string) =>
    employees.filter(
        (e) =>
            getPartnerEmployeeName(e).toLowerCase().includes(filter.toLowerCase()) ||
            e.email.toLowerCase().includes(filter.toLowerCase())
    )

export const getAssignedAndUnassignedBookkeepers = (
    employees: PartnerEmployee[],
    company: PartnerCompany
) => {
    interface BookkeeperAcccess {
        bookkeepers: PartnerEmployee[]
        unassignedBookkeepers: PartnerEmployee[]
    }
    const assignedAndUnassignedBookkeepers = employees.reduce(
        (prev: BookkeeperAcccess, curr) => {
            if (company.bookkeeperUserIds.includes(curr.userId)) {
                prev.bookkeepers.push(curr)
                return prev
            }
            prev.unassignedBookkeepers.push(curr)
            return prev
        },
        { bookkeepers: [], unassignedBookkeepers: [] }
    )
    return assignedAndUnassignedBookkeepers
}
