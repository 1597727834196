import React, { FC, useEffect } from 'react'
import { useParams, useNavigate, Routes, Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { Button, Result, Modal, Card, notification } from 'antd'

import { selectCompany } from 'store/modules/support/company/selectors'
import { selectCompanyStatus, selectUserStatus } from 'store/modules/support/selectors'
import {
    selectUser,
    selectModalActive,
    selectCompanyResourceId,
    selectUserEmail,
    selectUserFetched,
    selectNewUserId,
    selectPartnerEmployeeResourceId,
    selectPartnerResourceId,
} from 'store/modules/support/user/selectors'
import { actions as companyActions } from 'store/modules/support/company/slice'
import { actions as supportActions } from 'store/modules/support/slice'
import { actions as userActions } from 'store/modules/support/user/slice'

import { User, Role } from 'types/employee'
import type { FormValues } from './components/edit-information/validation-schema'
import EditInformation from './components/edit-information/edit-information'
import AdminPermissions from 'components/permissions/admin-permissions'
import { getUserRole } from 'utils/employees'

import { exhaustiveCheck } from 'utils/exhaustive-check'

import Spinner from 'components/content-spinner'

import { PageContentLayout } from 'components/layout-containers'
import { getUserTypes } from 'utils/user'
import { deactivateEmployee, deleteBookkeeper } from 'services/deimos/employees'
import { deleteUser, getUser } from 'services/auth/users'
import { useHasPermissions } from 'components/permission-guard/permission-guard'

import { UserDetails } from './components/user-details'
import { CardTabs } from './components/cards/cards-tabs'
import {
    useGetPartner,
    useGetPartnerEmployee,
    deletePartnerEmployee,
} from 'services/calypso/partner'
import UserTable from './components/user-table/user-table'
import { EmployeeType } from '@pleo-io/deimos'
import { bff } from './backend/bff-hooks'
import { useEmployeeIdFromRoles } from './hooks/use-employee-id-from-roles'

const UserPage: FC<React.PropsWithChildren<unknown>> = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const goBack = () => navigate(-1)
    const { id: userId, employeeId: entityEmployeeId } = useParams()
    const user = useSelector(selectUser)

    const employeeIdFromRole = useEmployeeIdFromRoles(user.roles)
    const employeeId = employeeIdFromRole || entityEmployeeId || ''

    const { data: employee } = bff.user.getEmployee.useQuery({ employeeId: employeeId })
    const { mutateAsync: createNewInviteAndDeactivateOldEmployee } =
        bff.user.createNewInviteAndDeactivateOldEmployee.useMutation()
    const newUserId = useSelector(selectNewUserId)
    const company = useSelector(selectCompany)
    const companyFetchStatus = useSelector(selectCompanyStatus)
    const userFetchStatus = useSelector(selectUserStatus)
    const modalActive = useSelector(selectModalActive)
    const companyResourceId = useSelector(selectCompanyResourceId)
    const partnerEmployeeId = useSelector(selectPartnerEmployeeResourceId) || ''
    const partnerId = useSelector(selectPartnerResourceId) || ''
    const userEmail = useSelector(selectUserEmail)
    const userFetched = useSelector(selectUserFetched)
    const canAdministrateBackoffice = useHasPermissions(['super'])

    const { data: employeesByUserId, refetch } = bff.user.getEmployeesByUserId.useQuery(userId)
    const { data: partnerEmployee } = useGetPartnerEmployee(partnerId, partnerEmployeeId) || []

    // We have a bug in Deimos data where partner employees may have the wrong role. So we are
    // replacing with correct role from Calypso. Read more on Notion:
    // https://www.notion.so/pleo/Role-changes-Partner-Employee-updates-4117ac75ac3b492884c4c3f2890b1a3b?pvs=4
    const employees = employeesByUserId?.map((it) =>
        it.partnerId && partnerEmployee ? { ...partnerEmployee, type: EmployeeType.PARTNER } : it
    )
    const isOrganizationUser = !!(employees && employees.filter((it) => !it.partnerId).length > 1)
    const isPartnerUser = !!partnerId

    const companyFetchFailed = companyFetchStatus === 'error'
    const { isAdmin, isBookkeeper } = getUserTypes(user)

    const initialValues = {
        email: userEmail,
        userRole: getUserRole(isAdmin ?? false),
        token: '',
    }

    const { data: partner } = useGetPartner(partnerId)

    const onEditInformationSubmit = (values: FormValues) => {
        if (!user.verified) {
            changeInviteEmail(values.email)
        } else {
            changeEmail(values.email)
        }
        changeRole(values.userRole)
    }

    const changeInviteEmail = async (newEmail: string) => {
        const emailChanged = userEmail !== newEmail
        if (emailChanged) {
            const response = await createNewInviteAndDeactivateOldEmployee({
                companyId: companyResourceId || '',
                employeeId,
                firstName: employee?.firstName || user.data?.profile?.name?.firstName || '',
                newEmail,
                oldEmail: user.email,
            })
            dispatch(userActions.setModalActive(false))
            notification.success({
                message: response.message,
                description: response.description,
                duration: 10,
            })
            navigate(`/customer-success/users/${response.newUserId}`)
        }
    }
    const changeEmail = (newEmail: string) => {
        const emailChanged = userEmail !== newEmail
        if (emailChanged) {
            dispatch(userActions.changeEmail({ userId, newEmail, oldEmail: user.email }))
            dispatch(userActions.setModalActive(false))
        }
    }
    const changeRole = (userRole: string) => {
        const roleChanged = isAdmin !== (userRole === 'owner')
        if (roleChanged) {
            dispatch(userActions.changeRole({ userId, employeeId, role: userRole }))
            dispatch(userActions.setModalActive(false))
        }
    }

    const fetchCompany = () => {
        if (companyResourceId) {
            dispatch(companyActions.fetchCompany(companyResourceId))
        }
    }

    const resetPasscode = () => dispatch(userActions.resetPasscode(userId))
    const resetTwoFactor = () => dispatch(userActions.resetTwoFactor(userId))
    const resendInvite = () =>
        dispatch(userActions.resendInvite({ companyId: company.id, employeeId }))
    const resendChangeEmail = () => dispatch(userActions.resendChangeEmail(userId))

    const deleteToken = () => dispatch(userActions.deleteToken(userId))

    // Not an ideal sollution, but the easiest way to achieve this
    // TODO: This page should be refactored into SWR
    const getUpToDateUser = (): Promise<User | null> | null => {
        if (!userFetched) {
            return null
        }
        return getUser(userId)
            .then((response) => response.json())
            .then((body) => body[0] ?? null)
            .catch(() => null)
    }

    const checkUserDeactivate = async () => {
        const localUser = await getUpToDateUser()
        if (!localUser) {
            navigate('/customer-success/search')
        } else {
            dispatch(userActions.fetchUser(userId))
            dispatch(supportActions.resetStatus())
        }
    }

    const onDeactivateEmployee = async () => {
        const localUser = await getUpToDateUser()
        if (!localUser) {
            return
        }
        const localEmployeeId = localUser.roles.find(
            (it) => it.resource === 'employee' && it.type === Role.OWNER
        )?.resourceId

        if (!localEmployeeId) {
            // If there is no role for the employee just delete the user
            await deleteUser(localUser.id)
        } else {
            if (isBookkeeper) {
                // We want to explictely delete a bookkeeper.
                // This ensure the roles are cleaned up in kerberos, the user record soft deleted in kerberos, and the employee record soft deleted in deimos
                await deleteBookkeeper(localEmployeeId, localUser.email)
            } else {
                await deactivateEmployee(localEmployeeId)
            }
        }

        return checkUserDeactivate()
    }

    const onDeactivatePartnerEmployee = async () => {
        const localUser = await getUpToDateUser()
        if (!localUser) {
            return
        }
        const localPartnerEmployeeId = localUser.roles.find(
            (it) => it.resource === 'partnerEmployee' && it.type === Role.OWNER
        )?.resourceId
        if (partner && localPartnerEmployeeId) {
            await deletePartnerEmployee(partner.id, localPartnerEmployeeId)
        }
        return checkUserDeactivate()
    }

    const onPermissionChange = (permissions: string[], checked: boolean) => {
        if (checked) {
            permissions.forEach((permission) => {
                dispatch(
                    userActions.setUserAdminPermission({
                        userId: user.id,
                        resourceId: permission,
                    })
                )
            })
        } else {
            permissions.forEach((permission) => {
                dispatch(
                    userActions.unsetUserAdminPermission({
                        userId: user.id,
                        resourceId: permission,
                    })
                )
            })
        }
    }

    const onCompanyClick = (companyId: string) =>
        navigate(`/customer-success/companies/${companyId}`)
    const onPartnerClick = () => navigate(`/customer-success/partners/${partnerId}`)

    const onGrantBackofficeAccess = () => dispatch(userActions.setUserAdmin({ userId: user.id }))
    const onRemoveBackofficeAccess = () => {
        dispatch(userActions.unsetUserAdmin({ userId: user.id }))
        dispatch(userActions.setModalActive(false))
    }

    const onEnableCardAccess = () => dispatch(userActions.enableCardAccess({ employeeId }))

    const resetModal = () => {
        dispatch(userActions.unsetUserAdminIfConfirmationFailed({ user }))
        dispatch(userActions.setModalActive(false))
    }

    useEffect(() => {
        dispatch(userActions.resetState())
    }, [dispatch, userId])

    useEffect(() => {
        if (!userFetched) {
            dispatch(userActions.fetchUser(userId))
            dispatch(supportActions.resetStatus())
        }
    }, [dispatch, userFetched, userId])

    useEffect(() => {
        if (newUserId && newUserId !== userId) {
            navigate(`../${newUserId}`)
        }
    }, [navigate, newUserId, userId])

    useEffect(() => {
        if (userFetched && !companyResourceId) {
            dispatch(companyActions.resetCompany())
            dispatch(supportActions.resetStatus())
        }
    }, [dispatch, companyResourceId, userFetched])

    useEffect(() => {
        // Fetch company if this user has an associated company
        // and the company hasn't already been fetched
        // and the company hasn't already failed to fetch
        if (companyResourceId && company.id !== companyResourceId && !companyFetchFailed) {
            dispatch(companyActions.fetchCompany(companyResourceId))
        }
    }, [dispatch, company, companyResourceId, companyFetchFailed])

    switch (userFetchStatus) {
        case 'fetching':
            return <Spinner />
        case 'error':
            return (
                <Result
                    status="500"
                    title="Something went wrong"
                    subTitle="We had trouble fetching the user"
                    extra={
                        <Button type="primary" onClick={goBack}>
                            Go back
                        </Button>
                    }
                />
            )
        case 'resolved':
        case 'default':
            return (
                <Routes>
                    <Route
                        path="/"
                        element={
                            <>
                                {' '}
                                <UserDetails
                                    goBack={goBack}
                                    user={user}
                                    employeeId={employeeId}
                                    isOrganizationUser={isOrganizationUser}
                                    partnerEmployeeId={partnerEmployeeId}
                                    partnerId={partnerId}
                                    partner={partner}
                                    company={company}
                                    companyFetchStatus={companyFetchStatus}
                                    primaryCompanyId={user?.primaryCompanyId}
                                    companyFetchRetry={fetchCompany}
                                    resetPasscode={resetPasscode}
                                    resetTwoFactor={resetTwoFactor}
                                    resendChangeEmail={resendChangeEmail}
                                    resendInvite={resendInvite}
                                    editInformationOnClick={() =>
                                        dispatch(userActions.setModalActive(true))
                                    }
                                    onCompanyClick={onCompanyClick}
                                    onPartnerClick={onPartnerClick}
                                    deleteToken={deleteToken}
                                />
                                <Modal
                                    title="Edit information"
                                    open={modalActive}
                                    onCancel={() => resetModal()}
                                    centered
                                    okText="Save"
                                    footer={null}
                                    destroyOnClose
                                >
                                    <EditInformation
                                        initialValues={initialValues}
                                        user={user}
                                        isOrganizationUser={isOrganizationUser}
                                        onSubmit={onEditInformationSubmit}
                                        onDeactivateEmployee={onDeactivateEmployee}
                                        onDeactivatePartnerEmployee={onDeactivatePartnerEmployee}
                                        onDeactivateUser={onDeactivateEmployee}
                                        onGrantBackofficeAccess={onGrantBackofficeAccess}
                                        onRemoveBackofficeAccess={onRemoveBackofficeAccess}
                                        canAdministrateBackoffice={canAdministrateBackoffice}
                                        onEnableCardAccess={onEnableCardAccess}
                                        cardAccess={employee?.hasCardAccess}
                                    />
                                </Modal>
                                <PageContentLayout>
                                    {(isOrganizationUser || isPartnerUser) && employees ? (
                                        <Card>
                                            <UserTable
                                                employees={employees}
                                                refetch={refetch}
                                                primaryCompanyId={user?.primaryCompanyId}
                                            />
                                        </Card>
                                    ) : (
                                        <>
                                            {user.admin && (
                                                <Card title="Admin permissions">
                                                    <AdminPermissions
                                                        permissions={user.permissions}
                                                        onPermissionChange={onPermissionChange}
                                                    />
                                                </Card>
                                            )}
                                            <CardTabs employeeId={employeeId} />
                                        </>
                                    )}
                                </PageContentLayout>
                            </>
                        }
                    ></Route>
                    <Route
                        path="/:employeeId"
                        element={
                            <>
                                {' '}
                                <UserDetails
                                    goBack={goBack}
                                    user={user}
                                    partnerEmployeeId={partnerEmployeeId}
                                    partnerId={partnerId}
                                    partner={partner}
                                    company={company}
                                    companyFetchStatus={companyFetchStatus}
                                    companyFetchRetry={fetchCompany}
                                    resetPasscode={resetPasscode}
                                    resetTwoFactor={resetTwoFactor}
                                    resendChangeEmail={resendChangeEmail}
                                    resendInvite={resendInvite}
                                    editInformationOnClick={() =>
                                        dispatch(userActions.setModalActive(true))
                                    }
                                    onCompanyClick={onCompanyClick}
                                    onPartnerClick={onPartnerClick}
                                    deleteToken={deleteToken}
                                />
                                <Modal
                                    title="Edit information"
                                    open={modalActive}
                                    onCancel={() => resetModal()}
                                    centered
                                    okText="Save"
                                    footer={null}
                                    destroyOnClose
                                >
                                    <EditInformation
                                        initialValues={initialValues}
                                        user={user}
                                        onSubmit={onEditInformationSubmit}
                                        onDeactivateEmployee={onDeactivateEmployee}
                                        onDeactivatePartnerEmployee={onDeactivatePartnerEmployee}
                                        onDeactivateUser={onDeactivateEmployee}
                                        onGrantBackofficeAccess={onGrantBackofficeAccess}
                                        onRemoveBackofficeAccess={onRemoveBackofficeAccess}
                                        canAdministrateBackoffice={canAdministrateBackoffice}
                                        onEnableCardAccess={onEnableCardAccess}
                                        cardAccess={employee?.hasCardAccess}
                                    />
                                </Modal>
                                <PageContentLayout>
                                    {user.admin && (
                                        <Card title="Admin permissions">
                                            <AdminPermissions
                                                permissions={user.permissions}
                                                onPermissionChange={onPermissionChange}
                                            />
                                        </Card>
                                    )}
                                    <CardTabs />
                                </PageContentLayout>
                            </>
                        }
                    ></Route>
                </Routes>
            )
        default:
            return exhaustiveCheck(userFetchStatus)
    }
}

export default UserPage
