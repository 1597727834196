import { config as localConfig } from './config/config.local.mjs'
import { config as devConfig } from './config/config.product-dev.mjs'
import { config as stagingConfig } from './config/config.staging.mjs'
import { config as productionConfig } from './config/config.production.mjs'
interface AmlAndBillsAnswers {
    highestPaymentThreshold: number
}

export const amlAndBillsAnswers: AmlAndBillsAnswers = {
    highestPaymentThreshold: 25000,
}

export const chromeExtension = {
    inspectorGadget: {
        id: 'bimbgolplmnibhcgcncgehhdbpciailp',
        msgType: {
            infoForPob: 'POB_INFO',
            clearInfoForPob: 'CLEAR_POB_INFO',
        },
    },
}

export interface Config {
    environment: 'test' | 'local' | 'feature' | 'product-dev' | 'staging' | 'production'
    version?: string
    auth: {
        oauth: {
            clientId: string
        }
        smsOtp: string
    }
    endpoints: {
        api: string
        ariel: string
        auth: string
        bff: string
        caliban: string
        callisto: string
        calypso: string
        cupid: string
        dionysus: string
        eirene: string
        hydra: string
        oberon: string
        phobos: string
        prospero: string
        skoll: string
        stripe: string
        telesto: string
        styx: string
        pandora: string
        exportDispatcher: string
        metis: string
        phoebe: string
        themis: string
        beyond: string
        customervulnerabilitytracker: string
        accend: string
    }
    bugsnag: {
        key?: any
        apiKey?: any
        releaseStage: 'development' | 'test' | 'staging' | 'production' | 'dev'
    }
    datadog: {
        token?: string
        rumAppId?: string
        env?: string
    }
    segment: { key: string; host?: string }
}

const host = window.location.host

const config = (() => {
    if (host.includes('local') || process.env.NODE_ENV === 'test') {
        return localConfig
    }
    if (host.includes('staging')) {
        return stagingConfig
    }
    if (host.includes('dev') || host.includes('local')) {
        return devConfig
    }
    if (host === 'backoffice.pleo.io') {
        return productionConfig
    }
    throw Error('Unable to determine the correct config file.')
})()

export default config
