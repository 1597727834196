import { PageContentLayout } from 'components/layout-containers'
import { Card } from 'antd'
import { bff } from 'pages/compliance/bff'
import StpCheckList from './components/stp-check-list'

export const StpCheck = ({ companyId }: { companyId: string }) => {
    const { data: stpCheckData } = bff.stpCheck.getStpCheckForCompany.useQuery({ companyId })

    return (
        <PageContentLayout>
            <Card title="Straight Through Processing (STP) Checks" style={{ marginBottom: '1rem' }}>
                <StpCheckList
                    checkResults={stpCheckData?.data ?? []}
                    loading={stpCheckData === undefined}
                />
            </Card>
        </PageContentLayout>
    )
}
