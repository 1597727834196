// @ts-check

/**
 * @type {import("./config.type").Config}
 */
export const config = {
    environment: 'product-dev',
    auth: {
        oauth: {
            clientId: '4b06e650-30ed-4032-a565-b9b0def84906',
        },
        smsOtp: '123456',
    },
    endpoints: {
        api: 'https://api.dev.pleo.io', // run deimos remotely
        // api: 'http://localhost:8083', // run deimos locally
        ariel: 'https://api.dev.pleo.io',
        auth: 'https://auth.dev.pleo.io',
        // auth: 'http://localhost:8082',
        beyond: 'https://beyond.dev.pleo.io',
        bff: '__INJECTED__',
        caliban: 'https://caliban.dev.pleo.io',
        callisto: 'https://callisto.dev.pleo.io',
        calypso: 'https://api.dev.pleo.io/partner-portal',
        cupid: 'https://cupid.dev.pleo.io',
        dionysus: 'https://dionysus.dev.pleo.io/',
        eirene: 'https://api.dev.pleo.io/',
        hydra: 'https://api.dev.pleo.io/rest/v1/billing/',
        oberon: 'https://oberon.dev.pleo.io',
        phobos: 'https://phobos.dev.pleo.io',
        prospero: 'https://prospero.dev.pleo.io',
        skoll: 'https://skoll.dev.pleo.io',
        stripe: 'https://dashboard.stripe.com/test',
        telesto: 'https://telesto.dev.pleo.io',
        styx: 'https://api.dev.pleo.io',
        pandora: 'https://api.dev.pleo.io/rest/v1/commercial-tooling',
        exportDispatcher: 'https://api.dev.pleo.io',
        metis: 'https://metis.dev.pleo.io/rest',
        phoebe: 'https://phoebe.dev.pleo.io',
        themis: 'https://api.dev.pleo.io',
        customervulnerabilitytracker: 'https://customer-vulnerability-tracker.dev.pleo.io',
        accend: 'https://dashboard.staging.withaccend.com'

    },
    bugsnag: {
        apiKey: 'ae0c2cf439c08d0a7a600ccaaad77160',
        releaseStage: 'dev',
    },
    datadog: {
        env: 'dev',
    },
    segment: {
        key: 'NSbydU5IReEaWGgk4BPxPzgdRUd4ibxf',
    },
}
