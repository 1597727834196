import React, { useState } from 'react'
import { Modal, Space, Input, Alert, Button, Typography, Timeline } from 'antd'
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    CommentOutlined,
    HourglassOutlined,
} from '@ant-design/icons'

import { bff } from '../bff-hooks'
import { useOddDetailContext } from '../context'
import { formatTimestamp } from 'packages/dates/dates'
import type { CaseTimelineEventEntryEventType } from 'bff/moons/generated/case-manager'

const { Text } = Typography

const MIN_COMMENT_LENGTH_CHARACTERS = 30

export const ConclusionModal = () => {
    const {
        caseId,
        conclusionModal: { isOpen, close },
    } = useOddDetailContext()
    const [comment, setComment] = useState('')
    const { data: { isWalletBlocked: displayWalletBlockedWarning } = {} } =
        bff.modals.getIsWalletBlocked.useQuery({ caseId })
    const { mutateAsync: setCaseAsConcluded, isLoading } =
        bff.modals.setCaseAsConcluded.useMutation()

    const handleConclude = async () => {
        await setCaseAsConcluded({ caseId, comment })
        close()
    }

    const canConclude =
        comment.length >= MIN_COMMENT_LENGTH_CHARACTERS && !displayWalletBlockedWarning

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            onOk={handleConclude}
            title="Conclude this ODD case?"
            okText={'Conclude'}
            okButtonProps={{ disabled: !canConclude, loading: isLoading }}
            destroyOnClose
        >
            <Space direction="vertical" style={{ width: '100%' }}>
                {displayWalletBlockedWarning && <WalletBlockedWarning />}
                <label htmlFor="conclusion-comment">
                    <Text>Add a comment (required)</Text>
                </label>
                <Input.TextArea
                    id="conclusion-comment"
                    placeholder="Add a comment"
                    minLength={MIN_COMMENT_LENGTH_CHARACTERS}
                    showCount
                    style={{ marginBottom: '1rem' }}
                    disabled={displayWalletBlockedWarning}
                    onChange={(e) => setComment(e.target.value)}
                />
            </Space>
        </Modal>
    )
}

export const OffboardedModal = () => {
    const {
        caseId,
        offboardedModal: { isOpen, close },
    } = useOddDetailContext()
    const [comment, setComment] = useState('')
    const { mutateAsync: setCaseAsOffboarded, isLoading } =
        bff.modals.setCaseAsOffboarded.useMutation()

    const handleSubmit = async () => {
        await setCaseAsOffboarded({ caseId, comment })
        close()
    }

    return (
        <Modal
            open={isOpen}
            onCancel={close}
            onOk={handleSubmit}
            title="Mark this ODD case as offboarded?"
            okText={'Offboarded'}
            okButtonProps={{
                loading: isLoading,
                disabled: comment.length < MIN_COMMENT_LENGTH_CHARACTERS,
            }}
        >
            <Input.TextArea
                placeholder="Add a comment"
                minLength={MIN_COMMENT_LENGTH_CHARACTERS}
                showCount
                style={{ marginBottom: '1rem' }}
                onChange={(e) => setComment(e.target.value)}
            />
        </Modal>
    )
}

const WalletBlockedWarning = () => {
    const { caseId } = useOddDetailContext()
    const { mutateAsync: setWalletBlockedAsIncomplete, isLoading } =
        bff.modals.setWalletBlockedAsIncomplete.useMutation()
    return (
        <Alert
            type="warning"
            message="Wallet Blocked"
            description={
                <Space direction="vertical">
                    <Text>
                        This ODD case cannot be concluded while the wallet is blocked. Please click
                        below to confirm that the wallet has been unblocked to proceed.
                    </Text>
                    <Button
                        type="primary"
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={() => setWalletBlockedAsIncomplete({ caseId })}
                    >
                        Wallet unblocked
                    </Button>
                </Space>
            }
        />
    )
}

export const HistoryModal = () => {
    const {
        caseId,
        historyModal: { isOpen, close },
    } = useOddDetailContext()
    const { data } = bff.modals.getCaseTimeline.useQuery({ caseId })

    const timelineItems = data?.timeline.map((event) => ({
        ...mapEventType(event.eventType),
        children: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={{ fontSize: '0.875em' }} type="secondary">
                    {formatTimestamp(event.by.at)} - {event.by.firstName} {event.by.lastName}
                </Text>
                <Text strong>{prettifyEventType(event.eventType)}</Text>
                <Text style={{ fontSize: '0.875em' }}>{event.description}</Text>
            </div>
        ),
    }))

    return (
        <Modal open={isOpen} onCancel={close} title="Case History" footer={null}>
            <Timeline items={timelineItems} style={{ marginTop: '16px' }} />
        </Modal>
    )
}

const mapEventType = (
    eventType: CaseTimelineEventEntryEventType
): { color: string; dot?: React.ReactNode } => {
    switch (eventType) {
        case 'CHECK_DELETED':
            return {
                color: 'red',
                dot: <CloseCircleOutlined />,
            }
        case 'CHECK_COMPLETED':
        case 'UPDATED_STATE_TO_ODD_COMPLETED':
        case 'UPDATED_STATE_TO_OFFBOARDING_COMPLETED':
            return {
                color: 'green',
                dot: <CheckCircleOutlined />,
            }
        case 'UPDATED_CLOSED_NOTES':
            return {
                color: 'grey',
                dot: <CommentOutlined />,
            }
        case 'UPDATED_STATE_TO_WAITING_FOR_CUSTOMER':
            return {
                color: 'orange',
                dot: <HourglassOutlined />,
            }
        default:
            return {
                color: 'gray',
            }
    }
}

const prettifyEventType = (string: CaseTimelineEventEntryEventType) => {
    const acronyms = ['ODD']

    return string
        .split('_')
        .map((word) =>
            acronyms.includes(word)
                ? word
                : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ')
}
