import { SettingOutlined } from '@ant-design/icons'
import { Button, MenuProps, Dropdown } from 'antd'
import styled from 'styled-components'

export default function AccountCardActions() {
    const actionItems: MenuProps['items'] = [
        {
            key: '1',
            label: 'Edit state',
            disabled: true,
        },
        {
            key: '2',
            label: 'Close/Write off account',
            disabled: true,
        },
        {
            key: '3',
            label: 'Waive/Write off interest',
            disabled: true,
        },
        {
            key: '4',
            label: 'Edit expiry date',
            disabled: true,
        },
        {
            key: '5',
            label: 'Edit interest rate',
            disabled: true,
        },
    ]
    return (
        <StyledButtonsContainer>
            <Dropdown menu={{ items: actionItems }} trigger={['click']}>
                <Button type="primary" icon={<SettingOutlined />}>
                    Actions
                </Button>
            </Dropdown>
        </StyledButtonsContainer>
    )
}

const StyledButtonsContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`
