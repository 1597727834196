// @ts-check

/**
 * @type {import("./config.type").Config}
 */
export const config = {
    environment: 'production',
    version: '__INJECTED__',
    auth: {
        oauth: {
            clientId: '561775de-20b0-41e2-a42d-fe12d4a2a02e',
        },
        smsOtp: '123456',
    },
    endpoints: {
        api: 'https://api.pleo.io',
        ariel: 'https://api.pleo.io',
        auth: 'https://auth.pleo.io',
        bff: '__INJECTED__',
        beyond: 'https://beyond.pleo.io',
        caliban: 'https://caliban.pleo.io',
        callisto: 'https://callisto.pleo.io',
        calypso: 'https://api.pleo.io/partner-portal',
        cupid: 'https://cupid.pleo.io',
        // dionysus is only deployed in staging
        dionysus: 'https://dionysus.staging.pleo.io/',
        eirene: 'https://api.pleo.io/',
        hydra: 'https://api.pleo.io/rest/v1/billing/',
        oberon: 'https://oberon.pleo.io',
        phobos: 'https://phobos.pleo.io',
        prospero: 'https://prospero.pleo.io',
        skoll: 'https://skoll.pleo.io',
        stripe: 'https://dashboard.stripe.com',
        telesto: 'https://telesto.pleo.io',
        pandora: 'https://api.pleo.io/rest/v1/commercial-tooling',
        styx: 'https://api.pleo.io',
        exportDispatcher: 'https://api.pleo.io',
        metis: 'https://metis.pleo.io/rest',
        phoebe: 'https://phoebe.pleo.io',
        themis: 'https://api.pleo.io/',
        customervulnerabilitytracker: 'https://customer-vulnerability-tracker.pleo.io',
        accend: 'https://dashboard.eu.withaccend.com'
    },
    bugsnag: {
        apiKey: 'ae0c2cf439c08d0a7a600ccaaad77160',
        releaseStage: 'production',
    },
    datadog: {
        env: 'production',
    },
    segment: {
        key: 'KeDM8mTOmZf4rFXpUCcQn98SsBK8BmQG',
    },
}
