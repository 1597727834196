import type { ProviderName } from './provider-name'
import type { Role } from './employee'
import type { CardStatus } from '../components/index.bff'

export interface Card {
    aboutToBeSwitched: boolean
    aboutToExpire: boolean
    canChangePin: string
    companyId: string
    walletId: string
    daysToExpiration: number | null
    daysToSwitchDate: number | null
    disabledByAdmin: boolean
    disabledByLimit: boolean
    embossedName: string | null
    embossedLine4: string | null
    employeeId: string
    employee?: CardEmployee
    enabled: boolean
    expiration: string
    id: string
    linked: boolean
    pan: string
    pinTriesExceeded: boolean
    providerName: ProviderName
    status: Status
    storebox: boolean
    type: Type
    velocity: Velocity
    meta?: {
        blockedByTriesExceeded?: boolean
    }
}

export interface CurrentCardInfo {
    id: string
    status: CardStatus
}

interface CardEmployee {
    id: string
    userId: string
    companyId: string
    firstName: string
    lastName: string
    email: string
    phone: string | null
    avatar: string | null
    jobTitle: string | null
    status: {}
    code: string | null
    role: Role
    createdAt: string
    updatedAt: string
    deletedAt: null
}

export enum Type {
    VIRTUAL = 'VIRTUAL',
    PHYSICAL = 'PHYSICAL',
    DEDICATED = 'DEDICATED',
    TEMPORARY = 'TEMPORARY',
}

export enum Status {
    LIVE = 'LIVE',
    RESTRICTED = 'RESTRICTED',
    LOST = 'LOST',
    STOLEN = 'STOLEN',
    DESTROYED = 'DESTROYED',
    EXPIRED = 'EXPIRED',
    DEACTIVATED = 'DEACTIVATED',
}

export const ChangeStatus = Object.keys(Status).filter(
    (s) => ![Status.DEACTIVATED, Status.EXPIRED].includes(s as Status)
)

export const IrreversibleStatus = [Status.LIVE, Status.RESTRICTED]

export enum Velocity {
    CARD = 'CARD',
    CARD_50 = 'CARD_50',
    CARD_100 = 'CARD_100',
    CARD_250 = 'CARD_250',
    CARD_500 = 'CARD_500',
    CARD_1000 = 'CARD_1000',
    CARD_2000 = 'CARD_2000',
    CARD_2500 = 'CARD_2500',
    CARD_3000 = 'CARD_3000',
    CARD_5000 = 'CARD_5000',
    CARD_10000 = 'CARD_10000',
    CARD_15000 = 'CARD_15000',
    CARD_25000 = 'CARD_25000',
    CARD_50000 = 'CARD_50000',
    CARD_70000 = 'CARD_70000',
    CARD_100000 = 'CARD_100000',
    CARD_150000 = 'CARD_150000',
    CARD_200000 = 'CARD_200000',
    CARD_HIGH_FREQUENCY = 'CARD_HIGH_FREQUENCY',
}

export enum UpdateVelocity {
    DEFAULT = 'DEFAULT',
    HIGH_FREQUENCY = 'HIGH_FREQUENCY',
}
