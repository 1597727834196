import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'

import { User, Employee, Avatar, Role, EmployeePermission } from 'types/employee'
import type { UserPage } from 'store/modules/state'
import { Card, Status, CurrentCardInfo } from 'types/card'
import type { StatusUpdatePayload, VelocityUpdatePayload, VelocityUpdateResponse } from './types'
import type { CurrentStatus } from 'types/status'

export const initialState: UserPage = {
    user: {
        id: '',
        email: '',
        language: '',
        verified: false,
        verifyToken: null,
        active: false,
        acceptedTerms: false,
        data: {
            promo: '',
            profile: {
                id: '',
                raw: {},
                name: { lastName: '', firstName: '' },
                type: '',
                email: '',
                phone: '',
                picture: '',
            },
            changeEmail: '',
        },
        createdAt: '',
        admin: false,
        permissions: [],
        roles: [],
        hasPasscode: false,
    },
    employee: {
        id: '',
        verified: true,
        active: true,
        acceptedTerms: true,
        emailChange: '',
        userId: '',
        companyId: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        email: '',
        phone: '',
        avatar: { url: '' } as Avatar,
        createdAt: '',
        updatedAt: '',
        deletedAt: '',
        code: '',
        role: Role.OWNER,
        limits: [],
        departments: [],
        reviewer: [],
        permissions: [],
    },
    modalActive: false,
    cards: [],
    cardsFetchingStatus: 'default',
    currentCardInfo: {
        id: '',
        status: Status.LIVE,
    },
}

const { actions: generatedActions, reducer } = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetState: () => initialState,
        setModalActive: (state, { payload }: PayloadAction<boolean>) => {
            state.modalActive = payload
        },
        updatedUser: (state, { payload }: PayloadAction<User>) => {
            state.user = payload
        },
        updatedEmployee: (state, { payload }: PayloadAction<Employee>) => {
            state.employee = payload
        },
        updatedChangeEmail: (state, { payload }: PayloadAction<string>) => {
            // Force UI change - is replaced on next user refresh
            state.user.verifyToken = 'random-string'
            state.user.data.changeEmail = payload
        },
        updatedEmail: (state, { payload }: PayloadAction<string>) => {
            state.user.email = payload
        },
        setNewUserId: (state, { payload }: PayloadAction<string>) => {
            state.newUserId = payload
        },
        updatedRole: (state, { payload }: PayloadAction<string>) => {
            const newRoles = state.user.roles.map((role) =>
                role.resource === 'company' ? { ...role, type: payload } : role
            )
            state.user.roles = newRoles
        },
        setPermission: (state, { payload }: PayloadAction<string>) => {
            state.user.permissions.push(payload)
        },
        unsetPermission: (state, { payload }: PayloadAction<string>) => {
            state.user.permissions = state.user.permissions.filter((p) => p !== payload)
        },
        enableUserAdmin: (state) => {
            state.user = { ...state.user, admin: true, permissions: ['admin'] }
        },
        revokeUserAdmin: (state) => {
            state.user = { ...state.user, admin: false, permissions: [] }
        },
        updatedCards: (state, { payload }: PayloadAction<Card[]>) => {
            state.cards = payload
        },
        setCardsFetchingStatus: (state, { payload }: PayloadAction<CurrentStatus>) => {
            state.cardsFetchingStatus = payload
        },
        setCardStatus: (state, { payload }: PayloadAction<StatusUpdatePayload>) => {
            const card = state.cards.find((c) => c.id === payload.cardId)
            if (card) {
                const index = state.cards.indexOf(card)
                state.cards[index].status = payload.status
            }
        },
        setCardVelocity: (state, { payload }: PayloadAction<VelocityUpdateResponse>) => {
            const card = state.cards.find((c) => c.id === payload.cardId)
            if (card) {
                const index = state.cards.indexOf(card)
                state.cards[index].velocity = payload.velocity
            }
        },
        setCurrentCardInfo: (state, { payload }: PayloadAction<CurrentCardInfo>) => {
            state.currentCardInfo = payload
        },
        enabledCardAccess: (state, _: PayloadAction<string>) => {
            state.employee.permissions = [
                ...(state.employee.permissions ?? []),
                EmployeePermission.CARD_ACCESS,
            ]
        },
    },
})

const fetchUser = createAction<string>('user/fetchUser')
const fetchCards = createAction<{ companyId: string; employeeId: string }>('user/fetchCards')
const fetchCard = createAction<{ cardId: string }>('user/fetchCard')
const updateCardStatus = createAction<StatusUpdatePayload>('user/updateCardStatus')
const updateCardVelocity = createAction<VelocityUpdatePayload>('user/updateCardVelocity')
const resetPinTries = createAction<string>('user/resetPinTries')
const resetPasscode = createAction<string>('user/resetPasscode')
const resetTwoFactor = createAction<string>('user/resetTwoFactor')
const resendChangeEmail = createAction<string>('user/resendChangeEmail')
const resendInvite = createAction<{ companyId?: string | null; employeeId: string }>(
    'user/resendInvite'
)
const changeEmail = createAction<{ userId: string; oldEmail: string; newEmail: string }>(
    'user/changeEmail'
)
const changeRole = createAction<{ userId: string; employeeId: string; role: string }>(
    'user/changeRole'
)
const deleteEmployee = createAction<{ employeeId: string; email: string }>('user/deleteEmployee')
const setUserAdmin = createAction<{ userId: string }>('user/setUserAdmin')
const unsetUserAdmin = createAction<{ userId: string }>('user/unsetUserAdmin')
const setUserAdminPermission = createAction<{ userId: string; resourceId: string }>(
    'user/setUserAdminPermission'
)
const unsetUserAdminPermission = createAction<{ userId: string; resourceId: string }>(
    'user/unsetUserAdminPermission'
)
const unsetUserAdminIfConfirmationFailed = createAction<{ user: User }>(
    'user/unsetUserAdminIfConfirmationFailed'
)

const enableCardAccess = createAction<{ employeeId: string }>('user/enableCardAccess')

const deleteToken = createAction<string>('user/deleteRefreshTokens')

export const userReducer = reducer
export const actions = {
    ...generatedActions,
    fetchUser,
    fetchCards,
    fetchCard,
    updateCardStatus,
    updateCardVelocity,
    resetPinTries,
    resetPasscode,
    resetTwoFactor,
    resendChangeEmail,
    resendInvite,
    changeEmail,
    changeRole,
    deleteEmployee,
    setUserAdmin,
    unsetUserAdmin,
    setUserAdminPermission,
    unsetUserAdminPermission,
    unsetUserAdminIfConfirmationFailed,
    enableCardAccess,
    deleteToken,
}
