import { Form, Input, Modal, Select, Space, Button, message, Tooltip } from 'antd'
import { useState } from 'react'
import { useUser } from 'providers/user-context'
import { bff } from '../../pages/product/group-permissions/bff'
import { uniq } from 'lodash'
import { CopyOutlined } from '@ant-design/icons'

export const RequestPermissionsModal = ({
    open,
    onCancel,
}: {
    open: boolean
    onCancel: () => void
}) => {
    const [permissionsToAdd, setPermissionsToAdd] = useState([])
    const [permissionsToRemove, setPermissionsToRemove] = useState([])
    const [requestReason, setRequestReason] = useState('')

    const { permissions: currentUserPermissions } = useUser()
    const { data: oktaPermissions, isLoading: oktaPermissionsLoading } =
        bff.permissions.listAllPermissions.useQuery(
            { params: { include_deleted: false } },
            { refetchOnWindowFocus: false }
        )

    const { mutate: submitSelfServicePermissionChangeRequest } =
        bff.permissions.submitSelfServicePermissionChangeRequest.useMutation()

    const permissionsNotAssigned =
        oktaPermissions
            ?.map((oktaPermission) => {
                if (!uniq(currentUserPermissions).includes(oktaPermission.tag)) {
                    return oktaPermission
                }
            })
            .filter((element) => {
                return element !== undefined
            }) || []

    const enrichedPermissionsAssigned =
        uniq(currentUserPermissions)
            .map((permission) => {
                const permissionObject = oktaPermissions?.find((oktaPermission) => {
                    return oktaPermission.tag === permission
                })
                return permissionObject
            })
            .filter((element) => {
                return element !== undefined
            }) || []

    const clearValues = () => {
        setPermissionsToAdd([])
        setPermissionsToRemove([])
        setRequestReason('')
    }

    const submitRequest = async () => {
        try {
            await submitSelfServicePermissionChangeRequest({
                permissionsChangeRequestBody: {
                    permissionIdsToAdd: permissionsToAdd,
                    permissionIdsToRemove: permissionsToRemove,
                    reason: requestReason,
                },
            })

            clearValues()
            onCancel()

            message.success('Request sent successfully')
        } catch (e) {
            message.error((e as Error).message)
        }
    }

    const currentUserPermissionsIds = enrichedPermissionsAssigned.map(
        (permission) => permission?.id
    )

    const copyPermissions = () => {
        navigator.clipboard.writeText(currentUserPermissionsIds.join(', '))
        message.success('Permissions copied to clipboard')
    }

    const handleOnCancel = () => {
        clearValues()
        onCancel()
    }

    return (
        <Modal
            open={open}
            onCancel={() => handleOnCancel()}
            title="Permission Request"
            footer={null}
            destroyOnClose
        >
            <Form layout="vertical">
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Form.Item label="Permissions to add">
                        <Select
                            disabled={oktaPermissionsLoading}
                            options={permissionsNotAssigned.map((permission) => ({
                                label: `${permission?.tag} - (${permission?.description})`,
                                value: permission?.id,
                            }))}
                            optionFilterProp="label"
                            mode="tags"
                            onChange={(value) => setPermissionsToAdd(value)}
                            tokenSeparators={[', ']}
                        />
                    </Form.Item>
                    <Form.Item label="Permissions to remove">
                        <Select
                            disabled={oktaPermissionsLoading}
                            options={enrichedPermissionsAssigned.map((permission) => ({
                                label: `${permission?.tag} - (${permission?.description})`,
                                value: permission?.id,
                            }))}
                            optionFilterProp="label"
                            mode="tags"
                            onChange={(value) => setPermissionsToRemove(value)}
                            tokenSeparators={[', ']}
                        />
                    </Form.Item>
                    <Form.Item label="Reason" required>
                        <Input.TextArea
                            onChange={(input) => setRequestReason(input.target.value)}
                        />
                        <p style={{ marginTop: '10px' }}>
                            The request will be sent to your manager on Slack for approval via the
                            BackOffice Permo-bot.
                        </p>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" disabled={!requestReason} onClick={submitRequest}>
                            Submit
                        </Button>
                        <Button style={{ marginLeft: '10px' }} type="dashed" href="https://www.notion.so/pleo/Backoffice-Permissions-Official-17a81ae1a84141c695c1c897b04620df?pvs=4" target="_blank">
                            Need help?
                        </Button>

                        <Tooltip title="Copy permissions to clipboard">
                            <Button style={{ float: 'right' }} onClick={() => copyPermissions()}>
                                Copy my permissions <CopyOutlined />
                            </Button>
                        </Tooltip>
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    )
}
