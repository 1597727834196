import type { State } from 'store/modules/state'
import { createSelector } from '@reduxjs/toolkit'
import { Role } from 'types/employee'

export const selectUser = (state: State) => state.support.userPage.user
export const selectNewUserId = (state: State) => state.support.userPage.newUserId
export const selectEmployee = (state: State) => state.support.userPage.employee
export const selectCards = (state: State) => state.support.userPage.cards
export const selectCardsFetchingStatus = (state: State) =>
    state.support.userPage.cardsFetchingStatus
export const selectCurrentCardInfo = (state: State) => state.support.userPage.currentCardInfo
export const selectModalActive = (state: State) => state.support.userPage.modalActive
export const selectUserFetched = createSelector([selectUser], (user) => user && user.id !== '')
export const selectCompanyResourceId = createSelector(
    [selectUser],
    (user) =>
        user?.roles.find(
            (r) => r.resource === 'company' && [Role.OWNER, Role.MEMBER].includes(r.type as Role)
        )?.resourceId
)
export const selectPartnerResourceId = createSelector(
    [selectUser],
    (user) => user?.roles.find((r) => r.resource === 'partner')?.resourceId
)
export const selectUserEmail = createSelector([selectUser], (user) =>
    user?.data.changeEmail ? user?.data.changeEmail : user?.email
)

export const selectPartnerEmployeeResourceId = createSelector(
    [selectUser],
    (user) =>
        user?.roles.find((r) => r.resource === 'employee' && r.parentResource === 'partner')
            ?.resourceId
)
