import type { EmployeeType } from '@pleo-io/deimos'

export interface Employee {
    type?: EmployeeType
    id: string
    verified?: boolean
    active?: boolean
    acceptedTerms?: boolean
    emailChange?: string
    userId: string
    companyId?: string | null
    partnerId?: string | null
    firstName: string
    lastName: string
    jobTitle?: string
    email: string
    phone: string
    avatar?: Avatar
    code?: string
    createdAt: string
    updatedAt: string
    deletedAt?: string
    role: Role | PartnerRole | null
    departments?: Department[]
    limits?: Limit[]
    reviewer?: string[]
    permissions?: EmployeePermission[]
    isSpendingEntity?: boolean
}

export interface EmployeePlusInsurance extends Employee {
    isInsured: boolean
    planCode?: string
    insuranceEndDate?: string
}

export interface PartnerEmployee {
    id: string
    userId: string
    partnerId: string
    firstName: string
    lastName: string
    phone: string
    avatar?: Avatar
    email: string
    role: PartnerRole
    type: EmployeeType
    createdAt: string
    updatedAt: string
    deletedAt?: string
    clientCompanyIds: String[]
}

export interface EmployeePayload {
    email: string
    employeeId: string
}
export interface Avatar {
    url: string
}

export interface Department {
    id: string
    name: string
    code: string
    role: string
}

export interface Limit {
    id: string
    employeeId: string
    group: string
    type: string
    value: LimitValue
    createdAt: string
    updatedAt: string
    deletedAt: string
    spend: number
    currency: string
    left: number
}

export interface LimitValue {
    value: string
    date?: string
}

export enum Role {
    OWNER = 'owner',
    MEMBER = 'member',
    BOOKKEEPER = 'bookkeeper',
    BOOKKEEPER_BASIC = 'bookkeeper-basic',
    BOOKKEEPER_EXTENDED = 'bookkeeper-extended',
    BOOKKEEPER_MANAGED = 'bookkeeper-managed',
}

export enum PartnerRole {
    OWNER = 'owner',
    MEMBER = 'member',
}

export enum DisplayRole {
    ADMIN = 'Admin',
    EMPLOYEE = 'Employee',
    BOOKKEEPER_BASIC = 'bookkeeper-basic',
    BOOKKEEPER_EXTENDED = 'bookkeeper-extended',
}

export enum PartnerDisplayRole {
    ADMIN = 'Admin',
    MEMBER = 'Member',
}
export enum EmployeePermission {
    CARD_ACCESS = 'cardAccess',
}

export enum UserTFAType {
    PHONE = 'phone',
    GOOGLE_AUTH = 'googleAuth',
}

export interface GetUser {
    id: string
    primaryCompanyId: string
}

export interface User {
    id: string
    email: string
    language: string | null
    verified: boolean
    active: boolean
    acceptedTerms: boolean
    data: UserData
    createdAt: string
    deletedAt?: string
    admin: boolean
    permissions: string[]
    roles: UserRole[]
    hasPasscode?: boolean | null
    phone?: string | null
    phoneVerified?: boolean
    scaEnabled?: boolean
    scaSecondFactor?: UserTFAType | null
    verifyToken: string | null
    primaryCompanyId?: string
}

export interface UserData {
    promo: string | null
    profile: UserProfile
    changeEmail?: string
}
export interface UserProfile {
    id: string | null
    raw: {}
    name?: UserName
    type: string
    email: string
    phone: string
    picture: string | null
}

export interface UserName {
    lastName: string
    firstName: string
}

export interface UserRole {
    id: string
    userId: string
    parentResource: string | null
    parentResourceId: string | null
    resource: string
    resourceId: string
    type: string
    createdAt: string
}

export type BookkeeperDisplayedRole = 'basic' | 'extended' | 'partner'

export interface Bookkeeper {
    userId: string
    firstName: string
    lastName: string
    email: string
    displayedRole?: BookkeeperDisplayedRole
}

export type EmployeeRoleType =
    | 'Admin'
    | 'Employee'
    | 'Bookkeeper (extended)'
    | 'Bookkeeper (basic)'
    | 'Orphan Employee'
    | 'Partner Admin'
    | 'Partner Member'
    | 'Unknown'
