import { Button, Card, message, Modal, Space, Table, Tag, Typography } from 'antd'
import type { ColumnProps } from 'antd/lib/table'
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons'

import { ContentContainer, PageContentLayout } from 'components/layout-containers'

import { FraudCheckRecord, useFraudCheckRecords } from 'services/deimos/proof-of-funds'

import dayjs from 'packages/dayjs'

const { Text } = Typography

const PayOpsFraudCheck = () => {
    const { data, error, mutations } = useFraudCheckRecords()
    const isFraudCheckRecordsLoading = !data && !error

    const columns: ColumnProps<FraudCheckRecord>[] = [
        {
            title: 'Company ID',
            dataIndex: 'companyId',
            render: (name, record: FraudCheckRecord) =>
                record.companyId ? (
                    <Space direction="vertical" align="start">
                        <Text copyable={{ text: name }}>{record.companyId}</Text>
                    </Space>
                ) : undefined,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (_name, record: FraudCheckRecord) => <Tag color="warning">{record.status}</Tag>,
        },
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (_name, record: FraudCheckRecord) => dayjs(record.createdAt).format('lll'),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_name, record: FraudCheckRecord) => (
                <Space>
                    <Button
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        onClick={() =>
                            Modal.confirm({
                                centered: true,
                                icon: <ExclamationCircleOutlined />,
                                content: `Are you sure? This will APPROVE the fraud check record of the company "${record.companyId}"`,
                                onOk: async () => {
                                    try {
                                        await mutations.approveFraudCheckRecord(record.id)
                                        message.success('Successfully approved fraud check record!')
                                    } catch (err) {
                                        message.error(
                                            `Failed to approve fraud check record: ${
                                                (err as Error).message
                                            }`
                                        )
                                    }
                                },
                            })
                        }
                    >
                        Approve
                    </Button>
                </Space>
            ),
        },
    ]

    return (
        <PageContentLayout>
            <Card>
                <ContentContainer>
                    <Table
                        data-testid="fraud-list-table"
                        rowKey="id"
                        dataSource={data}
                        columns={columns}
                        loading={isFraudCheckRecordsLoading}
                        pagination={false}
                    />
                </ContentContainer>
            </Card>
        </PageContentLayout>
    )
}

export default PayOpsFraudCheck
